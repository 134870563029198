import Icon from '../../components/Icon';
import ReactTextTransition from 'react-text-transition';
import { useTranslation } from 'react-i18next';
import { Loader, Tooltip, Flex } from '@mantine/core';
import ClickableRegion from '../../components/ClickableRegion';
import { OrganizationStats } from '@manifest-cyber/types/interface/db';
import styles from './AtAGlance.module.scss';

interface Props {
  isFetching: boolean;
  fetchedStats: OrganizationStats | undefined;
}

export const AtAGlance = ({ isFetching, fetchedStats }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="glance anim-slideInUpShort">
      <Flex gap={4} align="baseline">
        <h2 className="widget-header">{t('page.dashboard.glance.header')}</h2>
        <Tooltip
          label={t('page.dashboard.glance.refresh-rate-note')}
          position="top"
          withArrow
        >
          <span>
            <Icon icon="info-circle" iconSharp classNames={styles.tooltipInfoIcon} />
          </span>
        </Tooltip>
      </Flex>

      <div className="glance-grid">
        <div className="glance-box">
          <ClickableRegion
            regionLabel={t('page.dashboard.glance.tooltips.sboms')}
            className="glance-head"
            href="/uploads"
          >
            <Icon icon="file-shield" fixedWidth iconSharp />
            <span>{t('page.dashboard.glance.row.sboms')}</span>
          </ClickableRegion>
          <div className="glance-value">
            <ReactTextTransition
              children={
                isFetching ? (
                  <Loader />
                ) : (
                  fetchedStats?.countTotalSboms?.toLocaleString() || '--'
                )
              }
              springConfig={{ tension: 100, friction: 30 }}
            />
          </div>
        </div>
        <div className="glance-box">
          <ClickableRegion
            regionLabel={t('page.dashboard.glance.tooltips.assets')}
            className="glance-head"
            href="/assets"
          >
            <Icon icon="browsers" fixedWidth iconSharp />
            <span>{t('page.dashboard.glance.row.assets')}</span>
          </ClickableRegion>
          <div className="glance-value">
            <ReactTextTransition
              children={
                isFetching ? (
                  <Loader />
                ) : (
                  fetchedStats?.countTotalActiveAssets?.toLocaleString() || '--'
                )
              }
              springConfig={{ tension: 100, friction: 30 }}
            />
          </div>
        </div>
        <div className="glance-box">
          <ClickableRegion
            regionLabel={t('page.dashboard.glance.tooltips.vulnerabilities')}
            className="glance-head"
            href="/vulnerabilities"
          >
            <Icon icon="brake-warning" fixedWidth iconSharp />
            <span>{t('page.dashboard.glance.row.vulnerabilities')}</span>
          </ClickableRegion>
          <div className="glance-value">
            <ReactTextTransition
              children={
                isFetching ? (
                  <Loader />
                ) : (
                  fetchedStats?.countUniqueAssetVulnerabilities?.toLocaleString() || '--'
                )
              }
              springConfig={{ tension: 100, friction: 30 }}
            />
          </div>
        </div>
        <div className="glance-box">
          <ClickableRegion
            regionLabel={t('page.dashboard.glance.tooltips.components')}
            className="glance-head"
            href="/components"
          >
            <Icon icon="box-open-full" fixedWidth iconSharp />
            <span>{t('page.dashboard.glance.row.components')}</span>
          </ClickableRegion>
          <div className="glance-value">
            <ReactTextTransition
              children={
                isFetching ? (
                  <Loader />
                ) : (
                  fetchedStats?.countTotalActiveAssetComponents?.toLocaleString() || '--'
                )
              }
              springConfig={{ tension: 100, friction: 30 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
