// import Loading from "../../Loading";
import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';
// import classNames from "classnames";
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import { extractScoreSeverity } from '../../CvssScore/CvssScore';
import { ManifestTag } from '../../ManifestTag/ManifestTag';
import './ScatterBubble.scss';
import { ManifestBubbleChartDataType, ManifestBubbleChartOptions } from './types';

/**
 * Custom Scatter/Bubble Chart built with D3
 * @param param0
 * @returns
 */
const ManifestScatterBubble = ({
  data,
  dataType = 'default',
  options = defaultOptions,
}: {
  data: ManifestBubbleChartDataType[];
  dataType: string;
  options?: ManifestBubbleChartOptions;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const svgRef = useRef(null);
  const tooltipRef = useRef(null);
  const svgContainer = useRef<any>(null); // The PARENT of the SVG

  // State to track width and height of SVG Container
  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(400);

  // This function calculates width and height of the container
  const getSvgContainerSize = () => {
    const newWidth = svgContainer?.current?.clientWidth;
    setWidth(newWidth);
    const newHeight = svgContainer?.current?.clientHeight;
    setHeight(newHeight > 450 ? 450 : 450);
  };

  useEffect(() => {
    // detect 'width' and 'height' on render
    getSvgContainerSize();
    // listen for resize changes, and detect dimensions again when they change
    window.addEventListener('resize', getSvgContainerSize);
    // cleanup event listener
    return () => window.removeEventListener('resize', getSvgContainerSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * D3
   */
  useEffect(() => {
    // data
    let dataset = data.sort((a, b) => {
      if (a.x < b.x) {
        return -1;
      }
      if (a.x > b.x) {
        return 1;
      }
      return 0;
    });

    // Accessors
    let xAccessor: any;
    let yAccessor: any;

    // Datatypes
    switch (dataType) {
      case 'default':
        xAccessor = (singleRecord: ManifestBubbleChartDataType) => singleRecord.x;
        yAccessor = (singleRecord: ManifestBubbleChartDataType) => singleRecord.y;
        break;
      default:
        throw new Error(`${dataType} is an unknown dataType prop`);
    }

    // Dimensions
    let dimensions = {
      width: width,
      height: height,
      margins: 70,
      containerHeight: 0,
      containerWidth: 0,
    };

    dimensions.containerHeight = dimensions.height - dimensions.margins * 2;
    dimensions.containerWidth = dimensions.width - dimensions.margins * 2;

    // Primary SVG Selector
    const svg = d3
      .select(svgRef.current)
      .classed('scatter-bubble-inner', true)
      .attr('width', dimensions.width + 10)
      .attr('height', dimensions.height + 20);

    // Clear previous content on refresh.
    const everything = svg.selectAll('*');
    everything.remove();

    // Individual container
    const container = svg
      .append('g')
      .classed('scatter-bubble-container', true)
      .attr('transform', `translate(${dimensions.margins}, ${dimensions.margins})`);

    // Scales
    const colorScale = d3
      .scaleOrdinal()
      .range(['#7c8490', '#1EBCE2', '#ED765D', 'rgba(204, 54, 99, 1)', '#F2C94C'])
      .domain(['low', 'medium', 'high', 'critical', 'kev']);

    const xScale = d3
      .scaleLinear()
      // @ts-ignore
      .domain([
        options?.xaxis?.min || dataset[0]?.x,
        options?.xaxis?.max || d3.max(dataset, xAccessor),
      ])
      .range([0, dimensions.containerWidth])
      .nice();

    const yScale = d3
      .scaleLinear()
      // @ts-ignore
      .domain([
        options?.yaxis?.min || d3.min(dataset, yAccessor),
        options?.yaxis?.max || d3.max(dataset, yAccessor),
      ])
      .range([dimensions.containerHeight, 0])
      .nice();

    const colorValue = (d: any) => {
      let { severity } = extractScoreSeverity(d?.context);
      severity = severity || 'unknown';
      return `${
        d?.context?.kevData?.inKEV || d?.context?.kevData?.inKEVPreviously
          ? 'kev'
          : severity
      }`
        .trim()
        .toLowerCase();
    };

    const currentEpoch = Date.now();

    // Calculate age of vuln in MS
    const calculateVulnerabilityAge = (dateStr: string) =>
      currentEpoch - new Date(dateStr).getTime();

    // Derive max vulnerability age (we'll scale size based on this), or default to 2001-01-01 (first dataset from NVD)
    const maxVulnerabilityAge =
      d3.max(dataset, (d) => calculateVulnerabilityAge(`${d?.context?.publishDate}`)) ||
      978307200000;

    // Define a scale for the age
    const vulnerabilityAgeScale = d3
      .scaleLinear()
      .domain([0, maxVulnerabilityAge]) // maxVulnerabilityAge can be the maximum age in your dataset
      .range([256, 256]); // Min and max area or size scaling range

    // Define symbol generator for different shapes
    const symbol = d3
      .symbol()
      .size((d) => {
        // Get current vuln's age in MS
        const vulnerabilityAge = calculateVulnerabilityAge(`${d?.context?.publishDate}`);

        // Get size based on the vulnerability age
        let size = vulnerabilityAgeScale(vulnerabilityAge);

        // If the vulnerability is in KEV (Known Exploited Vulnerabilities), ensure a minimum size
        if (d?.context?.kevData?.inKEV || d?.context?.kevData?.inKEVPreviously) {
          size = Math.max(size, 192);
        }

        return size;
      })
      .type((d) => {
        return d?.context?.kevData?.inKEV
          ? d3.symbolDiamond2
          : d?.context?.kevData?.inKEVPreviously
            ? d3.symbolDiamond
            : d3.symbolCircle;
      });

    // Draw Datapoints
    const plots = container
      .selectAll('.chart-plot')
      .data(dataset)
      .join('path')
      .attr('class', `chart-plot`)
      .attr('d', symbol)
      .attr(
        'transform',
        (d) => `translate(${xScale(xAccessor(d))}, ${yScale(yAccessor(d))})`,
      )
      // @ts-ignore
      .attr('fill', (d) => colorScale(colorValue(d)));

    // Tooltip event listeners
    plots
      .on('mouseover', (event, d) => {
        let { severity, score } = extractScoreSeverity(d?.context);
        severity = severity || 'Unknown';

        d3.select('#tooltip')
          .style('display', 'block')
          .style('left', (event.layerX || 0) + 'px')
          .style('top', (event.layerY || 0) + 'px')
          .select('.tooltip-title')
          .text(`${d?.context?.cveId}`);

        d3.select('#tooltip').select('.tooltip-severity strong').text(
          // Show severity + normalized CVSS score. Will look like 'CRITICAL (9.8)'.
          `${severity} (${score})`,
        );
        d3.select('#tooltip')
          .select('.tooltip-epss strong')
          .text(`${Math.round(d?.context?.epssScore * 10000) / 100}%`);
        d3.select('#tooltip')
          .select('.tooltip-published')
          .text(
            `Published ${DateTime.fromISO(d?.context?.publishDate).toLocaleString(
              DateTime.DATETIME_MED,
            )}`,
          );
        d3.select('#tooltip')
          .select('.tooltip-kev')
          .attr('style', d.context?.kevData?.inKEV ? 'display: block' : 'display: none');
      })
      .on('mouseleave', () => {
        d3.select('#tooltip').style('display', 'none');
      })
      .on('click', (event, d) => {
        navigate(`/vulnerability/${d?.context?.cveId}`);
      });

    // Y-Axis Setup
    const yAxis = d3
      .axisLeft(yScale)
      .ticks(6)
      .tickFormat((d) => `${d}%`);
    const yAxisGroup = container.append('g').classed('axis yAxis', true).call(yAxis);
    // Add label to Y-Axis
    yAxisGroup
      .append('text')
      .classed('axis-title', true)
      .attr('x', -dimensions.containerHeight / 2)
      .attr('y', -dimensions.margins + 12)
      .text(options?.yaxis?.label || 'Y-Axis')
      .style('transform', 'rotate(270deg)')
      .style('text-anchor', 'middle');

    // X-Axis
    const xAxis = d3.axisBottom(xScale).ticks(6).tickPadding(10);
    const xAxisGroup = container
      .append('g')
      .classed('axis xAxis', true)
      .style('transform', `translateY(${dimensions.containerHeight}px)`)
      .call(xAxis);

    xAxisGroup
      .append('text')
      .classed('axis-title', true)
      .attr('x', dimensions.containerWidth / 2)
      .attr('y', dimensions.margins)
      .text(options?.xaxis?.label || 'X-Axis');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataType, width, height]); // redraw chart if data changes

  const isEngineerElementsEnabled = useFeatureFlag('engineerElements');

  return (
    <div ref={svgContainer} className="manifest-scatter-bubble">
      <svg ref={svgRef} />
      <div ref={tooltipRef} id="tooltip">
        <div className="tooltip-row">
          <h5 className="tooltip-title">
            {t('page.dashboard.scatter.tooltip.vulnerability')}
          </h5>
          <span className="tooltip-kev">
            <ManifestTag variant="kev" />
          </span>
        </div>
        <hr />
        <ul>
          <li className="tooltip-severity">
            <strong>#</strong> {t('page.dashboard.scatter.tooltip.severity')}
          </li>
          <li className="tooltip-epss">
            <strong>#</strong> {t('page.dashboard.scatter.tooltip.epss')}
          </li>
          <li className="tooltip-published">
            {t('page.dashboard.scatter.tooltip.publishDate')}
          </li>
        </ul>
      </div>
      {isEngineerElementsEnabled && (
        <div className="hotzone-overlay">
          <span>{t('global.highest-risk')}</span>
        </div>
      )}
    </div>
  );
};

const defaultOptions: ManifestBubbleChartOptions = {
  tooltipPadding: 10,
};

export default ManifestScatterBubble;
