import { AssetCountVulnerabilities } from '@manifest-cyber/types/interface/db';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/Icon/Icon';
import { InterfaceFullAsset } from '../../../../../types/FullInterfaces';

interface Props {
  fetchedAsset: InterfaceFullAsset;
}

export const RiskOverviewCard = ({ fetchedAsset }: Props) => {
  const { countVulnerabilities, countLicenseIssues, riskScore } = fetchedAsset;
  const { t } = useTranslation();

  const numInKev = countVulnerabilities?.inKEV || 0;
  const numMitigate = countVulnerabilities?.mitigate || 0;
  const numMonitor = countVulnerabilities?.monitor || 0;
  const numTotal = countVulnerabilities?.total || 0;
  const numLicenseIssues = countLicenseIssues?.total || 0;

  return (
    <div className={`risk-overview-card box-${riskScore}-risk`}>
      <div className={`risk-overview-card-background bg-${riskScore}-risk`} />
      <div className="risk-overview-card-content">
        <div className="left-side">
          <h3>{t('page.asset.risk')} </h3>
          <div
            className={`risk-score ${
              riskScore === 'high'
                ? 'critical'
                : riskScore === 'medium'
                  ? 'medium'
                  : 'low'
            }`}
          >
            {t(`page.asset.risk-score.${riskScore}`)}
          </div>
          {riskScore === 'low' && (
            <p className="no-major-vulns">{t('page.asset.no-major-vulns-identified')}</p>
          )}
          {!riskScore && (
            <p className="no-major-vulns">{t('page.asset.risk-calculating')}</p>
          )}
          <ul>
            <li>
              {numInKev === 0 ? (
                <Icon icon="check-circle" color="var(--color-label-success)" />
              ) : (
                <Icon icon="triangle-exclamation" color="var(--color-color-critical)" />
              )}
              <span>
                {t('page.asset.bullets.numKEV', {
                  count: numInKev,
                })}
              </span>
            </li>
            <li>
              {numMitigate === 0 ? (
                <Icon icon="check-circle" color="var(--color-label-success)" />
              ) : (
                <Icon icon="triangle-exclamation" color="var(--color-color-critical)" />
              )}
              <span>
                {t('page.asset.bullets.numMitigate', {
                  count: numMitigate,
                })}
              </span>
            </li>
            <li>
              {numMonitor === 0 ? (
                <Icon icon="check-circle" color="var(--color-label-success)" />
              ) : (
                <Icon
                  icon="triangle-exclamation"
                  color={
                    numMonitor >= 20
                      ? 'var(--color-color-critical)'
                      : numMonitor >= 5
                        ? 'var(--color-color-medium)'
                        : 'var(--color-color-low)'
                  }
                />
              )}
              <span>
                {t('page.asset.bullets.numMonitor', {
                  count: numMonitor,
                })}
              </span>
            </li>
            <li>
              {numLicenseIssues === 0 ? (
                <Icon icon="check-circle" color="var(--color-label-success)" />
              ) : (
                <Icon icon="triangle-exclamation" color="var(--color-color-critical)" />
              )}
              <span>
                {t('page.asset.bullets.numLicenseIssue', {
                  count: numLicenseIssues,
                })}
              </span>
            </li>
          </ul>
        </div>
        <div className="right-side">
          {countVulnerabilities && numTotal > 0 && (
            <div className="chart-wrapper">
              <div className="total-vulns">
                <div className="title">{t('page.asset.active-vulns')}</div>
                <div className="value">{numTotal}</div>
              </div>
              <Chart
                options={getChartOptions(countVulnerabilities)}
                series={getChartValues(countVulnerabilities)}
                height="230"
                type="donut"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export function getChartOptions(
  vulnCounts: AssetCountVulnerabilities,
  pdfView: boolean = false,
): ApexOptions {
  const colors = ['#CC3663', '#ED765D', '#F3A93C', '#B8BFCB', '#7C8490'];
  const pdfColors = ['#CC3663', '#ED765D', '#F3A93C', '#B8BFCB', '#7C8490'];

  const labels = ['Critical', 'High', 'Medium', 'Low', 'Info'].map((severity) => {
    return `${severity}`;
  });

  if (pdfView) {
    return {
      colors: pdfColors,
      chart: {
        id: 'risk-overview-chart-pdf',
        background: '#FFF',
        animations: {
          enabled: false,
        },
        type: 'donut',
      },
      labels,
      legend: {
        show: false,
        position: 'right',
        floating: true,
        offsetX: -70,
        fontFamily: 'Karla, Helvetica, sans-serif',
        fontSize: '16px',
        fontWeight: 400,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: '78%',
            labels: {
              show: true,
              value: {
                show: true,
                color: '#000',
                fontFamily: 'Karla, Helvetica, sans-serif',
                fontSize: '40px',
                fontWeight: 500,
              },
              total: {
                show: true,
                showAlways: true,
                color: '#8b8f98',
                fontFamily: 'Karla, Helvetica, sans-serif',
                fontSize: '22px',
                fontWeight: 500,
                label: 'TOTAL',
              },
            },
          },
        },
      },
    };
  }

  return {
    colors,
    chart: {
      id: 'risk-overview-chart',
      animations: {
        enabled: true,
      },
      type: 'donut', // Remove this to hide low/info
    },
    labels,
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '84px',
        },
      },
    },
  };
}

export function getChartValues(vulnCounts: AssetCountVulnerabilities): number[] {
  const { critical, high, medium, low, info } = vulnCounts;
  const series = Object.values({ critical, high, medium, low, info });
  return series as number[];
}
