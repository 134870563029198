import {
  TextComponent,
  TextComponentProps,
} from '@manifest-cyber/design-system/lib/components/TextComponent';
import { Box, Flex, Popover, ScrollArea, Skeleton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import clsx from 'clsx';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { GLOBAL_SEARCH_WITH_SINGLE_ITEM_PER_SECTION_STUB } from '../../__mocks__/data/globalSearchResponse.mock';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { Logger } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { ActionableCard } from '../ActionableCard/ActionableCard';
import BasicSearch from '../BasicSearch';
import ClickableRegion from '../ClickableRegion';
import { extractScoreSeverity, parseSeverityAndScore } from '../CvssScore/CvssScore';
import Icon from '../Icon';
import { ProductIcon } from '../Icons/ProductIcon/ProductIcon';
import styles from './ManifestSearch.module.scss';
import useFetchSearch, { GlobalSearchMappedata } from './useFetchSearch';

const RECENT_SEARCHES_STORAGE_KEY = 'recent-searches';
const getRecentSearchFromStorage = (): string[] => {
  try {
    const recentFromLocal: string[] = JSON.parse(
      window.localStorage.getItem(RECENT_SEARCHES_STORAGE_KEY) || '[]',
    );

    // Only add the items that are >= 2 char, and only add first five.
    return recentFromLocal.filter((r: string) => r.length >= 2).slice(0, 5);
  } catch (err) {
    Logger.error('Error while fetching recent searches', err);
    return [];
  }
};

const SearchResults = ({
  query,
  results,
  currentOrgId,
  isLoading = false,
}: {
  query: string;
  results?: GlobalSearchMappedata;
  isLoading: boolean;
  currentOrgId: string;
}) => {
  const { t } = useTranslation();
  const { products, assets, vulnerabilities, components } = isLoading
    ? GLOBAL_SEARCH_WITH_SINGLE_ITEM_PER_SECTION_STUB
    : results || GLOBAL_SEARCH_WITH_SINGLE_ITEM_PER_SECTION_STUB;
  const hasResults =
    results &&
    (results.assets.length > 0 ||
      results.components.length > 0 ||
      results.vulnerabilities.length > 0 ||
      results.products.length > 0);

  return (
    <Flex direction={'column'} w="100%">
      <div className={styles.topResultsTitle}>
        <TextComponent variant="headerSmall" color="title">
          {t('search.topResults')}
        </TextComponent>
      </div>

      {!hasResults && !isLoading && (
        <Flex className={styles.noResults} align="center" justify="center" w="100%">
          <TextComponent color="muted" variant="bodySmall">
            {t('search.notFoundResults')}: "{query}"
          </TextComponent>
        </Flex>
      )}
      {(hasResults || isLoading) && (
        <>
          {(isLoading || products.length > 0) && (
            <Section
              title={t('search.products')}
              link={{
                text: t('search.view-all'),
                label: t('search.view-all'),
                href: `/products?productName=${query}`,
              }}
              isLoading={isLoading}
            >
              {products.map((product) => (
                <SearchOption
                  isLoading={isLoading}
                  rigthIcon={<ProductIcon /* height={16} width={20} */ />}
                  iconColor="yellow"
                  key={product.id}
                  tooltipLabel={t('search.assets-details')}
                  href={`/product/${product.id}/${currentOrgId || ''}`}
                  title={product.name}
                  subtitle={`${t('search.version')}: ${product.version}`}
                />
              ))}
            </Section>
          )}
          {(isLoading || assets.length > 0) && (
            <Section
              title={t('search.assets')}
              link={{
                text: t('search.view-all'),
                label: t('search.view-all'),
                href: `/assets?assetName=${query}`,
              }}
              isLoading={isLoading}
            >
              {assets.map(({ totalVulns, id, name, version, riskScore }) => {
                const riskScoreText = riskScore
                  ? t(`search.risk-score.${riskScore}`)
                  : '';
                const countVulnsText = t('search.asset.count-vulns', {
                  count: totalVulns,
                });

                return (
                  <SearchOption
                    isLoading={isLoading}
                    rigthIcon={<Icon icon="layer-group" iconStyle="fal" />}
                    iconColor="primary"
                    key={id}
                    tooltipLabel={t('search.assets-details')}
                    href={`/asset/${id}/${currentOrgId || ''}`}
                    title={`${name} ${version}`}
                    subtitle={
                      <>
                        {riskScoreText}
                        {riskScoreText && countVulnsText && (
                          <Box component="span" mx="8px">
                            &bull;
                          </Box>
                        )}
                        {countVulnsText}
                      </>
                    }
                  />
                );
              })}
            </Section>
          )}
          {(isLoading || components.length > 0) && (
            <Section
              title={t('search.components')}
              link={{
                text: t('search.view-all'),
                label: t('search.view-all'),
                href: `/components?componentName=${query}`,
              }}
              isLoading={isLoading}
            >
              {components.map((component) => (
                <SearchOption
                  isLoading={isLoading}
                  iconColor="green"
                  rigthIcon={<Icon icon="grid-2" iconStyle="fal" />}
                  key={component.id}
                  tooltipLabel={t('search.components-details')}
                  href={`/component/${component.id}/${currentOrgId || ''}`}
                  title={component.name + component.version}
                  subtitle={
                    component.ecosystemToDisplay &&
                    `${t('search.ecosystem')}: ${component.ecosystemToDisplay}`
                  }
                />
              ))}
            </Section>
          )}
          {(isLoading || vulnerabilities.length > 0) && (
            <Section
              title={t('search.vulnerabilities')}
              link={{
                text: t('search.view-all'),
                label: t('search.view-all'),
                href: `/vulnerabilities?cveId=${query}`,
              }}
              isLoading={isLoading}
            >
              {vulnerabilities.map((vulnerability) => {
                const { score, severity } = extractScoreSeverity(vulnerability);
                const recommendationText = `${t('global.recommendation')}: ${vulnerability.recommendedActionToDisplay}`;

                return (
                  <SearchOption
                    isLoading={isLoading}
                    iconColor="red"
                    rigthIcon={<Icon icon="triangle-exclamation" iconStyle="fal" />}
                    key={vulnerability.id}
                    tooltipLabel={t('search.vulnerabilities-details')}
                    href={`/vulnerability/${vulnerability.cveId}`}
                    title={vulnerability.cveId}
                    subtitle={
                      <>
                        {`${t('search.cvss')}: ${parseSeverityAndScore({
                          score,
                          severity,
                          emptyText: 'N/A',
                        })}`}
                        <Box component="span" mx="8px">
                          &bull;
                        </Box>
                        {recommendationText}
                      </>
                    }
                  />
                );
              })}
            </Section>
          )}
        </>
      )}
    </Flex>
  );
};

const SearchOption = ({
  tooltipLabel,
  href,
  title,
  subtitle,
  rigthIcon,
  iconColor,
  onClick,
  isLoading = false,
}: {
  tooltipLabel: string;
  title: string;
  rigthIcon: ReactNode;
  href?: string;
  subtitle?: ReactNode;
  iconColor?: TextComponentProps['color'];
  onClick?: () => void;
  isLoading?: boolean;
}) => {
  return (
    <Box component="li">
      <ClickableRegion
        disabled={isLoading}
        regionLabel={tooltipLabel}
        href={href}
        onClick={onClick}
        className={styles.searchOption}
      >
        <Flex className={styles.searchOptionContent} justify="space-between">
          <Flex gap="8px">
            {rigthIcon && (
              <div>
                <Skeleton h="auto" w="auto" visible={isLoading}>
                  <TextComponent color={iconColor}>{rigthIcon}</TextComponent>
                </Skeleton>
              </div>
            )}
            <Flex direction="column" gap="4px">
              <Skeleton h="auto" w="auto" visible={isLoading}>
                <TextComponent
                  variant="headerMedium"
                  color="base"
                  lineClamp={2}
                  className={styles.searchOptionTitle}
                >
                  {title}
                </TextComponent>
              </Skeleton>

              <Skeleton h="auto" w="auto" visible={isLoading}>
                {subtitle && (
                  <TextComponent
                    variant="bodyMedium"
                    color="muted"
                    className={styles.searchOptionSubtitle}
                  >
                    {subtitle}
                  </TextComponent>
                )}
              </Skeleton>
            </Flex>
          </Flex>
          <div className={styles.hoverArrow}>
            <Skeleton h="auto" w="auto" visible={isLoading}>
              <TextComponent color="title">
                <Icon icon="angle-right" iconStyle="fal" />
              </TextComponent>
            </Skeleton>
          </div>
        </Flex>
      </ClickableRegion>
    </Box>
  );
};

const Section = ({
  title,
  link,
  children,
  isLoading = false,
}: {
  title: string;
  link?: {
    text: string;
    label: string;
    href: string;
  };
  children: ReactNode;
  isLoading?: boolean;
}) => {
  return (
    <div className={styles.section}>
      <Flex align="center" justify="space-between" pt="12px" pb="4px" pr="16px" pl="16px">
        <Skeleton h="auto" w="max-content" visible={isLoading}>
          <TextComponent variant="headerAllCaps" color="muted">
            {title}
          </TextComponent>
        </Skeleton>
        {link && (
          <Skeleton h="auto" w="max-content" visible={isLoading}>
            <ClickableRegion
              regionLabel={link.label}
              href={link.href}
              disabled={isLoading}
            >
              <TextComponent variant="componentSmall" color="primary">
                {link.text}
              </TextComponent>
            </ClickableRegion>
          </Skeleton>
        )}
      </Flex>
      <ul className={styles.searchList}>{children}</ul>
    </div>
  );
};

const getIsQueryValid = (query: string) => query.length >= 2;

export const GlobalSearch = () => {
  const { t } = useTranslation();
  const [currentOrgId = ''] = useOrganizationId();
  const { pathname } = useLocation();
  const [recentSearches, setRecentSearches] = useState<string[]>(
    getRecentSearchFromStorage(),
  );
  const [query, setQuery] = useState<string>('');
  const hasValidLengthQuery = getIsQueryValid(query.trim());
  const clickRef = useRef<HTMLDivElement | null>(null);
  const [opened, { close, open }] = useDisclosure(false);
  const {
    data: searchResults,
    error,
    isFetching: isLoading,
    // isLoading,
    refetch,
  } = useFetchSearch({ query, enabled: hasValidLengthQuery });

  useEffect(() => {
    close();
  }, [pathname]);

  useEffect(() => {
    if (error) {
      Logger.error('Error while fetching search results', error);
    }
  }, [error]);

  useEffect(() => {
    if (searchResults && hasValidLengthQuery) {
      const newRecentValues = Array.from(
        new Set([query, ...getRecentSearchFromStorage()]),
      ).slice(0, 5);
      window.localStorage.setItem(
        RECENT_SEARCHES_STORAGE_KEY,
        JSON.stringify(newRecentValues),
      );
      setRecentSearches(newRecentValues);
    }
  }, [searchResults, query, hasValidLengthQuery]);

  return (
    <div className={styles.manifestSearch} ref={clickRef}>
      <Popover
        id="global-search-popover"
        radius={0}
        position="bottom-end"
        onClose={close}
        onOpen={open}
        width={'target'}
        opened={
          opened &&
          Boolean(
            recentSearches.length > 0 || searchResults || hasValidLengthQuery || error,
          )
        }
        withinPortal
        classNames={{
          dropdown: clsx(styles.popoverDropdown, styles.popoverWidth),
        }}
        middlewares={{ flip: false, shift: false }}
      >
        <Popover.Target>
          <BasicSearch
            iconColor="primary"
            onClick={() => open()}
            initialValue={query}
            debounceTime={800}
            onChange={setQuery}
            isLoading={isLoading}
          />
        </Popover.Target>

        <Popover.Dropdown mah={'70vh'} data-testid="global-search-popover">
          <>
            {Boolean(error) && (
              <Flex
                justify="center"
                align="center"
                h="100%"
                className={styles.rigthSection}
              >
                <ActionableCard
                  title={t('error.general.errorLoadingData')}
                  subtitle={t('error.general.errorLoadingDataSubtitle')}
                  Icon={<Icon icon="circle-exclamation" size="lg" />}
                  primaryAction={{
                    label: t('global.retry'),
                    onClick: () => {
                      refetch();
                    },
                    isLoading,
                  }}
                />
              </Flex>
            )}
            {(searchResults || hasValidLengthQuery) && !error && (
              <ScrollArea.Autosize
                placeholder={''}
                scrollbarSize={8}
                className={styles.rigthSection}
                classNames={{
                  viewport: styles.h100,
                  root: styles.h100,
                }}
              >
                <SearchResults
                  query={query}
                  results={searchResults}
                  isLoading={isLoading}
                  currentOrgId={currentOrgId ?? ''}
                />
              </ScrollArea.Autosize>
            )}
            {!searchResults &&
              !isLoading &&
              !hasValidLengthQuery &&
              recentSearches.length > 0 && (
                <Box className={styles.rigthSection}>
                  <Section title={t('search.recent')}>
                    {recentSearches.map((item) => (
                      <SearchOption
                        iconColor="muted"
                        rigthIcon={<Icon icon="clock" iconStyle="fal" />}
                        key={item}
                        tooltipLabel={t('search.click-to-search')}
                        title={item}
                        onClick={() => setQuery(item)}
                      />
                    ))}
                  </Section>
                </Box>
              )}
          </>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default GlobalSearch;
