import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { SelectItem } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { rejectFailedRequestOrEmptyData } from '../../../lib/request/rejectFailedRequest/rejectFailedRequest';

export const useFetchProductsCVEIDs = () => {
  const fetchUrl = `assets/cveids`; // TODO: Change this to the correct product URL https://linear.app/manifest-cyber/issue/MFST-4678

  return useQuery({
    staleTime: 60000, // 1 minute
    queryKey: ['products-cveids', `/${fetchUrl}`],
    queryFn: async (): Promise<SelectItem[]> => {
      const response: ManifestApiResponse<string[]> = await AxiosProxy.get({
        url: fetchUrl,
      });
      const validResponse = await rejectFailedRequestOrEmptyData(response);
      return validResponse.data
        .sort((cveIdA: string, cveIdB: string) => {
          if (cveIdA < cveIdB) {
            return -1;
          }
          if (cveIdA > cveIdB) {
            return 1;
          }

          return 0;
        })
        .map(
          (cveId: string): SelectItem => ({
            label: cveId,
            value: cveId,
          }),
        );
    },
  });
};
