import { Accordion, Input } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClickableRegion from '../../../../components/ClickableRegion';
import Icon from '../../../../components/Icon/Icon';
import appConfig from '../../../../configs/appConfig';
import '../../../../scss/pages/integration.scss';
import { IIntegration } from '../integrations-data';

interface Props {
  integrationInfo: IIntegration;
}

export const IntegrationAddStart = ({ integrationInfo }: Props) => {
  const { t } = useTranslation();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [githubAppUrl, setGithubAppUrl] = useState<string>(appConfig?.githubAppUrl || '');

  const onInstall = () => {
    window.open(githubAppUrl);
  };

  return (
    <>
      <div className="integration-page-content github-content">
        <h5>{t('page.userSettings.integrations.description')}</h5>
        <p>{integrationInfo.description}</p>
        <h5>{t('page.userSettings.integrations.features')}</h5>
        <ul>
          {integrationInfo.features.map((feature, index) => (
            <li key={index}>
              <Icon icon="check" color="var(--color-label-success)" />{' '}
              <div>{feature}</div>
            </li>
          ))}
        </ul>
        <Accordion chevron={false}>
          <Accordion.Item
            value="advanced-app-uri"
            style={{
              borderBottom: 'none',
            }}
          >
            <Accordion.Control
              className="advanced-controls"
              onClick={() => setShowAdvanced(!showAdvanced)}
            >
              <h5>{t('page.userSettings.integrations.advanced')}</h5>
              <Icon
                icon="chevron-down"
                className={`${showAdvanced ? 'is-flipped' : ''}`}
              />
            </Accordion.Control>
            <Accordion.Panel className="advanced-panel">
              <p className="advanced-label">
                {t('page.userSettings.integrations.github.appurl-label')}
              </p>
              <p className="advanced-description">
                {t('page.userSettings.integrations.github.appurl-description')}
              </p>
              <Input
                placeholder={t('page.userSettings.integrations.github.appurl-input-aria')}
                value={githubAppUrl}
                aria-label={t('page.userSettings.integrations.github.appurl-input-aria')}
                onChange={({ target }) => setGithubAppUrl(target.value)}
                mt={12}
                icon={<Icon icon="earth-americas" />}
              />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
      <ClickableRegion
        regionLabel={integrationInfo.installText}
        onClick={onInstall}
        className="integration-install-button"
      >
        {t('page.userSettings.integrations.github.install')}
      </ClickableRegion>
    </>
  );
};
