import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { USER_QUERY_KEY } from '../../../api/user/useFetchManifestUser';

interface UsePutLocaleParams {
  locale: string;
}

export const usePutLocale = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ locale }: UsePutLocaleParams) => {
      const response = await AxiosProxy.put({
        url: `/user/locale`,
        withCredentials: true,
        body: { locale },
      });

      if (!response.success) {
        return Promise.reject(response.errors);
      }

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY],
      });
    },
  });
};
