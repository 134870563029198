import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

export const getLanguageName = (langCode: string, locale: string = 'en') => {
  if (typeof Intl.DisplayNames === 'function') {
    try {
      const displayNames = new Intl.DisplayNames([locale], { type: 'language' });
      // Extract the primary language subtag (e.g., 'en' from 'en-US')
      const primaryLang = langCode.split('-')[0] || '';
      return displayNames.of(primaryLang) || langCode;
    } catch (error) {
      captureExceptionWithMessage('Error using Intl.DisplayNames:', error);
      return langCode;
    }
  }
  // Fallback if Intl.DisplayNames is not supported
  return langCode;
};
