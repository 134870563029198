import { Card, Divider, Flex, Text, Title, Tooltip } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAuth } from '../../../../hooks/useAuth';
import { FetchProductOverviewResponse } from '../../../../pages/Products/products/useFetchProductOverview';
import { usePutProductLabels } from '../../../../pages/Products/products/usePutProductLabels';
import Icon from '../../../Icon';
import ManageLabelsModal from '../../../Labels/ManageLabelsModal';
import styles from './About.module.scss';

type ProductOverviewData = FetchProductOverviewResponse['data'];

interface InterfaceProductOverviewTopVulns {
  description: ProductOverviewData['description'];
  labels: ProductOverviewData['labels'];
}

export const ProductOverviewAbout = ({
  description,
  labels,
}: InterfaceProductOverviewTopVulns) => {
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');
  const [labelEditModalOpen, setLabelEditModalOpen] = useState(false);
  let { productId } = useParams<{ productId: string }>();

  const {
    mutateAsync: putProductLabels,
    isLoading: isAddingLabels,
    isError: isErrorAddingLabels,
  } = usePutProductLabels();

  return (
    <>
      <Card className={styles.about}>
        <Title className={styles.title}>{t('page.product.about')}</Title>
        <Text className={styles.sectionTitle}>{t('page.product.description')}</Text>
        <Divider className={styles.divider} />
        {!!description ? (
          <Text className={styles.description}>{description}</Text>
        ) : (
          <Text className={styles.noDescription}>{t('page.product.noDescription')}</Text>
        )}
        <Text className={styles.sectionTitle}>{t('page.product.labels')}</Text>
        <Divider className={styles.divider} />
        <Flex wrap={'wrap'} gap={'4px'}>
          {!labels.length && (
            <span className={styles.noLabels}>{t('page.product.noLabels')}</span>
          )}
          {!!labels.length &&
            labels.map((label, index) => (
              <Tooltip
                multiline
                key={label._id?.toString() as string}
                label={<pre>{label.name}</pre>}
              >
                <div className={styles.labelContainer}>
                  <div className={styles.labelColorContainer}>
                    {Array.isArray(label.color) ? (
                      <div className={styles.multiColorContainer}>
                        {label.color.map((color: string, index: number) => {
                          return (
                            <div
                              key={index}
                              className={`${styles.labelColor} ${index > 0 ? styles.overlap : ''}`}
                              style={{ backgroundColor: color }}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div
                        className={styles.labelColor}
                        style={{ backgroundColor: label.color as string }}
                      />
                    )}
                  </div>
                  <div className={styles.labelName}>{label.name}</div>
                </div>
              </Tooltip>
            ))}
          {hasWriteAccess && (
            <Tooltip label={t('labels.editButtonTooltip.editLabels')}>
              <div className="edit-button-container">
                <Icon icon="pen" onClick={() => setLabelEditModalOpen(true)} />
              </div>
            </Tooltip>
          )}
        </Flex>
      </Card>
      {labelEditModalOpen && (
        <ManageLabelsModal
          key="product-overview-labels-modal"
          putLabels={putProductLabels}
          entityType="product"
          labelIds={{
            [productId?.toString() as string]: labels.map(
              (l) => l._id?.toString() as string,
            ),
          }}
          open={labelEditModalOpen}
          onClose={() => setLabelEditModalOpen(false)}
        />
      )}
    </>
  );
};
