import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { rejectFailedRequestOrEmptyData } from '../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { buildUrlWithParams } from '../../../lib/url/buildWithParams/buildWithParams';
import { ProductsFilters } from '../productsContext/products.reducer';

export interface ProductsQueryParams {
  enabled?: boolean;
  limit?: number;
  page?: number;
  sortColumn?: string;
  sortType?: 1 | -1;
  filters?: ProductsFilters;
}

export interface PaginationInfo {
  page: number;
  limit: number;
  totalReturn: number;
  totalCount: number;
}

export interface PaginatedResponse<T> {
  success: boolean;
  errors: string[];
  queryInfo: PaginationInfo;
  data: T[];
}

export type ApiFilter<K extends object> = {
  field: keyof K;
  value: K[keyof K];
};

export const useFetchProducts = ({
  enabled = true,
  limit,
  page,
  sortColumn,
  sortType,
  filters,
}: ProductsQueryParams) => {
  const sortParam =
    sortColumn && sortType
      ? {
          [sortColumn]: sortType,
          _id: sortType,
        }
      : undefined;

  const {
    onlyHasVulns,
    hasKevVulns,
    labels,
    recommendation,
    cveIds,
    onlyHasLicenseIssues,
    productName,
    cvssScore,
    cvssSeverity,
  } = filters ?? ({} as ProductsFilters);
  const hasVulnsFilter: ApiFilter<ProductsFilters> = {
    field: 'onlyHasVulns',
    value: onlyHasVulns,
  };
  const hasVulnsOnKevListFilter: ApiFilter<ProductsFilters> = {
    field: 'hasKevVulns',
    value: hasKevVulns,
  };

  const labelsFilter: ApiFilter<ProductsFilters> = { field: 'labels', value: labels };
  const recommendationFilter: ApiFilter<ProductsFilters> = {
    field: 'recommendation',
    value: recommendation,
  };

  const cveIdsFilter: ApiFilter<ProductsFilters> = { field: 'cveIds', value: cveIds };
  const hasLicenseIssuesFilter: ApiFilter<ProductsFilters> = {
    field: 'onlyHasLicenseIssues',
    value: onlyHasLicenseIssues,
  };

  const productNameFilter: ApiFilter<ProductsFilters> = {
    field: 'productName',
    value: productName,
  };

  const cvssScoreFilter: ApiFilter<ProductsFilters> = {
    field: 'cvssScore',
    value: cvssScore,
  };

  const cvssSeverityFilter: ApiFilter<ProductsFilters> = {
    field: 'cvssSeverity',
    value: cvssSeverity,
  };

  const filtersList: ApiFilter<ProductsFilters>[] = [];

  if (cvssScore && cvssScore.length === 2) {
    filtersList.push(cvssScoreFilter);
  }

  if (cvssSeverity && cvssSeverity.length > 0 && !cvssScore) {
    filtersList.push(cvssSeverityFilter);
  }

  if (hasKevVulns) {
    filtersList.push(hasVulnsOnKevListFilter);
  }
  if (productName && productName.length >= 2) {
    filtersList.push(productNameFilter);
  }

  if (onlyHasVulns) {
    filtersList.push(hasVulnsFilter);
  }

  if (onlyHasLicenseIssues) {
    filtersList.push(hasLicenseIssuesFilter);
  }
  if (labels && labels.length > 0) {
    filtersList.push(labelsFilter);
  }
  if (recommendation && recommendation.length > 0) {
    filtersList.push(recommendationFilter);
  }
  if (cveIds && cveIds.length > 0) {
    filtersList.push(cveIdsFilter);
  }

  const fetchUrl = buildUrlWithParams(`products`, {
    limit,
    page,
    sort: sortParam,
    filters: filtersList.length > 0 ? filtersList : undefined,
  });

  const queryKey = ['products', `/${fetchUrl}`];

  return useQuery({
    enabled: enabled,
    keepPreviousData: true,
    queryKey: queryKey,
    queryFn: async (): Promise<{
      data: InterfaceOrganizationProduct[];
      queryInfo: ManifestApiResponse<InterfaceOrganizationProduct>['queryInfo'];
    }> => {
      const response = await AxiosProxy.get({ url: fetchUrl });
      const sanitizedResponse =
        await rejectFailedRequestOrEmptyData<InterfaceOrganizationProduct[]>(response);

      return {
        data: sanitizedResponse.data.map((product) => ({
          ...product,
          countVulnerabilities: {
            ...product.countVulnerabilities,
            total: product.countVulnerabilities?.total ?? 0,
            mitigate: product.countVulnerabilities?.mitigate ?? 0,
          },
        })),
        queryInfo: sanitizedResponse.queryInfo,
      };
    },
    onError: (error: unknown) => {
      captureExceptionWithMessage(`Unable to fetch products:`, error);
    },
  });
};
