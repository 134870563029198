import {
  AssetCountVulnerabilities,
  VulnerabilityRiskAction,
} from '@manifest-cyber/types/interface/db';
import { Dispatch } from 'react';
export type ProductFilterCvssSeverity = keyof Pick<
  AssetCountVulnerabilities,
  'medium' | 'high' | 'critical' | 'low'
>;
export interface ProductsFilters {
  onlyHasVulns: boolean;
  hasKevVulns: boolean;
  onlyHasLicenseIssues: boolean;
  cvssScore?: [number, number];
  cvssSeverity: ProductFilterCvssSeverity[];
  isCustomCvssScoreSelected: boolean;
  productName: string;
  cveIds: string[];
  recommendation: VulnerabilityRiskAction[];
  labels: string[];
}

export interface ProductState {
  isOpenSidebar: boolean;
}

export type ProductStateAction = { type: 'SET_IS_OPEN_SIDEBAR'; payload: boolean };

export type ProductDispatch = Dispatch<ProductStateAction>;

export const productReducer = (
  state: ProductState,
  { type, payload }: ProductStateAction,
): ProductState => {
  switch (type) {
    case 'SET_IS_OPEN_SIDEBAR':
      return { ...state, isOpenSidebar: payload };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
