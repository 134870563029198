import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { ReactNode } from 'react';
import Icon from '../../components/Icon';
import styles from './useNotifications/Notifications.module.scss';

export interface ShowNotificationParams {
  title?: ReactNode;
  message?: ReactNode;
  action?: {
    label: string;
    onClick: () => void;
  };
}

const closeButtonProps = {
  size: 'md',
  iconSize: '80%',
};

const NotificationBody = (params: Omit<ShowNotificationParams, 'title'>) => (
  <div>
    {params.message && (
      <div>
        <span className={styles.message}>{params.message}</span>
      </div>
    )}
    {params.action && (
      <Button variant="default" className={styles.button} onClick={params.action.onClick}>
        {params.action.label}
      </Button>
    )}
  </div>
);

const showErrorNotification = (params: ShowNotificationParams) => {
  notifications.show({
    title: params.title,
    message: <NotificationBody message={params.message} action={params.action} />,
    withCloseButton: true,
    icon: <Icon icon="triangle-exclamation" className={styles.errorIcon} />,
    closeButtonProps,
    classNames: {
      root: styles.root,
      title: styles.title,
      icon: styles.iconContainer,
      closeButton: styles.closeButton,
    },
  });
};

const showSuccessNotification = (params: ShowNotificationParams) => {
  notifications.show({
    title: params.title,
    message: <NotificationBody message={params.message} action={params.action} />,
    withCloseButton: true,
    icon: <Icon icon="check-circle" className={styles.successIcon} />,
    closeButtonProps,
    classNames: {
      root: styles.root,
      title: styles.title,
      icon: styles.iconContainer,
      closeButton: styles.closeButton,
    },
  });
};

const showInfoNotification = (params: ShowNotificationParams) => {
  notifications.show({
    title: params.title,
    message: <NotificationBody message={params.message} action={params.action} />,
    withCloseButton: true,
    icon: <Icon icon="circle-info" className={styles.infoIcon} />,
    closeButtonProps,
    classNames: {
      root: styles.root,
      title: styles.title,
      icon: styles.iconContainer,
      closeButton: styles.closeButton,
    },
  });
};

interface ShowNotificationWithCustomIconParams extends ShowNotificationParams {
  icon?: ReactNode;
}

const showNotificationWithCustomIcon = (params: ShowNotificationWithCustomIconParams) => {
  notifications.show({
    title: params.title,
    message: <NotificationBody message={params.message} action={params.action} />,
    withCloseButton: true,
    icon: params.icon,
    closeButtonProps,
    classNames: {
      root: styles.root,
      title: styles.title,
      icon: styles.iconContainer,
      closeButton: styles.closeButton,
    },
  });
};

export const ManifestNotifications = {
  showErrorNotification,
  showSuccessNotification,
  showInfoNotification,
  showNotificationWithCustomIcon,
};

export const useNotifications = () => ({
  error: (notification: ShowNotificationParams) =>
    ManifestNotifications.showErrorNotification(notification),
  success: (notification: ShowNotificationParams) =>
    ManifestNotifications.showSuccessNotification(notification),
  info: (notification: ShowNotificationParams) =>
    ManifestNotifications.showInfoNotification(notification),
  custom: (notification: ShowNotificationWithCustomIconParams) =>
    ManifestNotifications.showNotificationWithCustomIcon(notification),
});
