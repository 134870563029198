import { Badge, Loader, Tabs } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { AssetsTable } from '../../components/DataTables/AssetsTable';
import Loading from '../../components/Loading';
import { ProductHeader } from '../../components/Product/ProductHeader/ProductHeader';
import { useAuth } from '../../hooks/useAuth';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { FailedApiResponse } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { Logger } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import NoMatch404 from '../NoMatch404';
import ProductOverview from '../ProductOverview';
import { useFetchProduct } from '../Products/products/useFetchProduct';
import { ProductVulnerabilitiesTab } from './ProductVulnerabilities/ProductVulnerabilities.tab';

export const Product = () => {
  const { t } = useTranslation();
  let { productId } = useParams<{ productId: string }>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeTab = pathname.split('/').pop();

  const {
    isLoading,
    error,
    data: productResponse,
  } = useFetchProduct({
    productId: productId,
  });

  const { user } = useAuth();
  const [currentOrgData, setCurrentOrgData] = useState<any>(null);
  const [currentOrgId] = useOrganizationId();
  const [orgName, setOrgName] = useState('');
  const orgId: any = useRef(null);

  useEffect(() => {
    if (currentOrgData?.name) {
      setOrgName(currentOrgData.name);
    }
  }, [currentOrgData]);

  useEffect(() => {
    const fetchOrgData = async (currentOrgId?: string) => {
      const getOrgData = await AxiosProxy.get({ url: 'organization' });
      if (getOrgData && getOrgData?.success && getOrgData?.data[0]) {
        setCurrentOrgData(getOrgData?.data[0]);
      }

      orgId.current = currentOrgId;
    };

    fetchOrgData();
  }, [currentOrgId, user]);

  if (
    (error as unknown as FailedApiResponse)?.errors?.some(
      (item) => item === 'error.products.not-found',
    )
  ) {
    Logger.error(`Unable to fetch product:`, error);
    return <NoMatch404 />;
  }

  return (
    <section className="page-product-details anim-slideInUpShort">
      <Helmet title={t('page.products.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      {isLoading && <Loading />}
      {productResponse?.data?._id && (
        <>
          <ProductHeader product={productResponse.data} orgName={orgName} />
          <Tabs
            value={activeTab}
            onTabChange={(newTab) => navigate(newTab as string, { replace: true })}
          >
            <Tabs.List>
              <Tabs.Tab value="overview">{t('page.product.product-overview')}</Tabs.Tab>
              <Tabs.Tab value="assets">
                {t('page.product.assets')}{' '}
                <Badge id="rewardId">
                  {isLoading ? (
                    <Loader color="white" size={'xs'} variant="dots" />
                  ) : productResponse.data.assetCount &&
                    productResponse.data.assetCount > 0 ? (
                    productResponse.data.assetCount.toLocaleString()
                  ) : (
                    0
                  )}
                </Badge>
              </Tabs.Tab>
              <Tabs.Tab value="vulnerabilities">
                {t('page.product.vulnerabilities')}{' '}
                <Badge>
                  {isLoading ? (
                    <Loader color="white" size={'xs'} variant="dots" />
                  ) : productResponse.data?.countVulnerabilities?.total &&
                    productResponse.data?.countVulnerabilities?.total > 0 ? (
                    productResponse.data?.countVulnerabilities?.total.toLocaleString()
                  ) : (
                    0
                  )}
                </Badge>
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>

          <Routes>
            <Route
              path="overview"
              element={
                <div>
                  <ProductOverview />
                </div>
              }
            />
            <Route
              path="assets"
              element={
                <div>
                  <AssetsTable embedded hasFilters={false} productId={productId} />
                </div>
              }
            />
            <Route
              path="vulnerabilities"
              element={
                <div>
                  <ProductVulnerabilitiesTab />
                </div>
              }
            />
            <Route path="*" element={<Navigate to={'overview'} replace />} />
          </Routes>
        </>
      )}
    </section>
  );
};
