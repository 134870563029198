import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useFetchManifestUser } from '../api/user/useFetchManifestUser';
import { useAuth } from '../hooks/useAuth';
import { Logger } from '../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { UserMenu } from '../shared-portal/Layout/UserMenu/UserMenu';
import Footer from './Footer';
import { ManifestLogo } from './Icons/ManifestLogoIcon/ManifestLogoIcon';
import LeftNav from './LeftNav/LeftNav';
import Toolbar from './Toolbar/Toolbar';
import TopBar from './TopBar';
import { useOrganizationAgreement } from '../hooks/organization/useOrganizationAgreement';
import { useUserAgreementAcceptance } from '../hooks/organization/useUserAgreementAcceptance';
import AgreementComponent from '../components/Agreement/Agreement';
import { AxiosProxy } from '../api/axiosProxy/axiosProxy';
import { Loader, Text } from '@mantine/core';
import { TopBanner } from './TopBanner/TopBanner';
import { useOrganizationId } from '../hooks/utils/useOrganizationId';
import { useFetchOrganization } from '../hooks/queries/useFetchOrganization';
import styles from './AuthedLayout.module.scss';

import i18n from '../i18n';

export const AuthedLayout = () => {
  const { user: savedUser, login, logout } = useAuth();
  const {
    data: fetchedUser,
    error: fetchUserError,
    isLoading: isLoadingUser,
  } = useFetchManifestUser();
  const { pathname } = useLocation();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLeftSidebarExpanded, setIsLeftSidebarExpanded] = useState(false);
  const user = savedUser || fetchedUser;
  const toggleExpandSidebar = () => {
    setIsLeftSidebarExpanded(!isLeftSidebarExpanded);
  };

  const [currentOrgId] = useOrganizationId();
  const { data: fetchedCurrentOrganization, isSuccess: isSuccessCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });

  const queryClient = useQueryClient();

  // State to track acceptance processing
  const [isAccepting, setIsAccepting] = useState(false);
  const [hasAcceptedAgreement, setHasAcceptedAgreement] = useState(false);

  // Fetch organization agreement
  const {
    data: agreementData,
    isLoading: isAgreementLoading,
    isSuccess: isSuccessOrganizationAgreement,
  } = useOrganizationAgreement();

  const isLMCO =
    isSuccessCurrentOrganization &&
    isSuccessOrganizationAgreement &&
    fetchedCurrentOrganization.domains.includes('lmco.com') &&
    agreementData.data?.requiresAgreement;

  // Fetch user's agreement acceptance status
  const { data: userAcceptanceData, isLoading: isUserAcceptanceLoading } =
    useUserAgreementAcceptance();

  const [isLanguageReady, setIsLanguageReady] = useState(false);

  useEffect(() => {
    if (fetchedUser?.locale) {
      i18n
        .changeLanguage(fetchedUser.locale)
        .then(() => {
          setIsLanguageReady(true);
        })
        .catch((error) => {
          Logger.captureExceptionWithMessage(
            'Error setting language in AuthedLayout',
            error,
          );
          setIsLanguageReady(true); // Proceed even if language change fails
        });
    } else {
      setIsLanguageReady(true);
    }
  }, [fetchedUser?.locale]);

  useEffect(() => {
    if (fetchedUser && !isEqual(savedUser, fetchedUser)) {
      login?.(fetchedUser, { shouldRedirect: false });
    }
  }, [fetchedUser, savedUser, login]);

  useEffect(() => {
    if (fetchUserError) {
      const statusCode = (fetchUserError as AxiosError)?.response?.status;
      const isUnauthorizedOrForbiddenError = Boolean(
        statusCode && [401, 403].includes(statusCode),
      );

      if (isUnauthorizedOrForbiddenError) {
        logout?.('expired');
      }

      Logger.captureExceptionWithMessage(
        'Error getting user profile in AuthedLayout',
        fetchUserError,
      );
    }
  }, [fetchUserError, logout]);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  if (isLoadingUser || !isLanguageReady) {
    return (
      <div className={styles.loaderContainer}>
        <Loader className="status-loader" speed="slow" size={18} />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (isAgreementLoading || isUserAcceptanceLoading) {
    return <Loader className="status-loader" speed="slow" size={18} />;
  }

  const requiresAgreement = agreementData?.data?.requiresAgreement;
  const agreementText = agreementData?.data?.agreementText || '';
  const agreementLastUpdated = agreementData?.data?.agreementLastUpdated;
  const organizationName = agreementData?.data?.organizationName;
  const userAcceptedAt = userAcceptanceData?.data?.agreementAcceptedAt;

  const needsToAcceptAgreement =
    requiresAgreement &&
    (!userAcceptedAt ||
      (agreementLastUpdated &&
        new Date(agreementLastUpdated) > new Date(userAcceptedAt)));

  if (needsToAcceptAgreement && !hasAcceptedAgreement) {
    return (
      <AgreementComponent
        agreementText={agreementText}
        orgName={organizationName}
        onAccept={async () => {
          // Update user's acceptance via PUT endpoint
          try {
            setIsAccepting(true);
            await AxiosProxy.put({
              url: 'organization/member/agreement',
              body: { agreementAcceptedAt: new Date().toISOString() },
            });
            // Invalidate user agreement acceptance query
            queryClient.invalidateQueries(['userAgreementAcceptance']);
            setHasAcceptedAgreement(true);
          } catch (error) {
            Logger.captureExceptionWithMessage(
              'Error updating agreement acceptance',
              error,
            );
            // Optionally, show an error message to the user
          } finally {
            setIsAccepting(false);
          }
        }}
        isAccepting={isAccepting}
      />
    );
  }

  if (!user.hasOrganizations) {
    return (
      <>
        <Toolbar
          withSidebar={false}
          isSidebarOpen={false}
          logo={<ManifestLogo height={30} width={120} />}
          rightSide={
            <UserMenu
              onLogOut={() => {
                navigate('/logout?reason=user-manual');
              }}
              texts={{
                logOut: t('navigation.logout.label'),
              }}
            />
          }
          actions={{
            goBack: () => navigate(-1),
            goBackLabel: t('global.goBack'),
            goForward: () => navigate(1),
            goForwardLabel: t('global.goForward'),
          }}
        />
        {/* Element just for testing purposes, we can remove when we have a better screen to show for non organization logged-in users */}
        <div data-testid="empty-page-non-org-user" />
      </>
    );
  }

  if (!user.isPaidOrgs && !pathname.includes('vendor-portal')) {
    return <Navigate to="/logout?reason=vendor-email-link-only" />;
  }

  return (
    <>
      {isLMCO && (
        <TopBanner
          text={
            <Text className={styles.orgNotes}>
              <Trans
                i18nKey="page.agreement.LCMOFooter"
                components={{
                  lmpi: <span style={{ color: 'white', fontWeight: 700 }} />,
                  tppi: <span style={{ color: 'white', fontWeight: 700 }} />,
                }}
              />
            </Text>
          }
        />
      )}
      <div
        className={`app-layout ${isLeftSidebarExpanded && 'left-sidebar-expanded'} ${
          isLMCO ? 'is-lcmo' : ''
        }`}
      >
        <LeftNav
          isPaidOrg={user.isPaidOrgs}
          toggleExpandSidebar={toggleExpandSidebar}
          withTopBanner={isLMCO}
        />
        <TopBar isPaidOrg={user.isPaidOrgs} />
        <div className="page-content-area">
          {outlet}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AuthedLayout;
