import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { Button, ButtonProps, Menu, MenuLabelProps, MenuProps } from '@mantine/core';
import { forwardRef, ReactNode } from 'react';
import Icon from '../Icon';
import styles from './Dropdown.module.scss';

export const Dropdown = ({
  children,
  targetProps,
  ...props
}: {
  children: ReactNode;
  targetProps: {
    label: string;
  } & Omit<ButtonProps, 'rightIcon'>;
} & MenuProps) => (
  <Menu
    {...props}
    classNames={{ dropdown: styles.menu, item: styles.item, itemLabel: styles.itemText }}
  >
    <Menu.Target>
      <Button
        variant="default"
        {...targetProps}
        rightIcon={
          <TextComponent color="white">
            <Icon icon="chevron-down" />
          </TextComponent>
        }
      >
        {targetProps.label}
      </Button>
    </Menu.Target>
    <Menu.Dropdown>{children}</Menu.Dropdown>
  </Menu>
);

Dropdown.Label = ({ children, ...props }: MenuLabelProps) => (
  <Menu.Label {...props}>
    <TextComponent variant="headerAllCaps" color="primary">
      {children}
    </TextComponent>
  </Menu.Label>
);

Dropdown.Item = forwardRef(
  (
    {
      children,
      ...props
    }: {
      children?: React.ReactNode;
      closeMenuOnClick?: boolean;
      icon?: React.ReactNode;
      rightSection?: React.ReactNode;
      onClick?: () => void;
      disabled?: boolean;
    },
    ref: React.Ref<HTMLButtonElement>,
  ) => (
    <Menu.Item {...props} ref={ref}>
      <TextComponent>{children}</TextComponent>
    </Menu.Item>
  ),
);
