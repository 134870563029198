import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { Drawer, Flex, MantineNumberSize } from '@mantine/core';
import styles from './Sidebar.module.scss';

export const Sidebar = ({
  title,
  titleIcon,
  isOpened,
  onClose,
  children,
  size,
}: {
  onClose(): void;
  title: string;
  titleIcon?: React.ReactNode;
  isOpened: boolean;
  children: React.ReactNode;
  size?: MantineNumberSize;
}) => {
  return (
    <Drawer
      title={
        <Flex align="center" justify="center" gap="8px">
          {titleIcon && <TextComponent color="primary">{titleIcon}</TextComponent>}
          <TextComponent variant="headerLarge" color="title">
            {title}
          </TextComponent>
        </Flex>
      }
      onClose={onClose}
      opened={isOpened}
      position="right"
      size={size || 'xs'}
      shadow="xl"
      overlayProps={{ opacity: 0.3 }}
      classNames={{
        content: styles.drawer,
        header: styles.header,
        body: styles.body,
      }}
      styles={{
        inner: {
          top: '60px',
        },
      }}
    >
      {children}
    </Drawer>
  );
};
