import { InterfaceOrganizationAsset } from '@manifest-cyber/types/interface/dbTables';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';

interface UseProductsAssociationProps {
  hasErrorCallback: (errors: string[]) => void;
  clearRowSelection: VoidFunction;
  openNotificationModal: VoidFunction;
}

interface ApiResponseWithErrors<T> extends AxiosResponse<T> {
  errors: string[];
}

export const useProductsAssociation = ({
  hasErrorCallback,
  clearRowSelection,
  openNotificationModal,
}: UseProductsAssociationProps) => {
  const queryClient = useQueryClient();

  return useMutation<
    ApiResponseWithErrors<InterfaceOrganizationAsset[]>,
    unknown,
    { organizationProductIds: (string | undefined)[]; organizationAssetIds: string[] }
  >({
    mutationFn: (body) => {
      return AxiosProxy.post({
        url: `products/batchWriteToProducts`,
        withCredentials: true,
        body,
      });
    },
    onSuccess: (response: ApiResponseWithErrors<InterfaceOrganizationAsset[]>) => {
      if (response.errors.length) {
        hasErrorCallback(response.errors);
      } else {
        clearRowSelection();
        openNotificationModal();
        hasErrorCallback([]);
        queryClient.invalidateQueries({
          queryKey: [`assets`],
        });
        queryClient.invalidateQueries({
          queryKey: [`products`],
        });
      }
    },
  });
};
