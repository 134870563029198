import { Alert, Button, Collapse, Group, Modal } from '@mantine/core';
import { Dispatch, FormEvent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductFormData } from '../../../pages/Products/products/usePostProduct';
import { useProductModal } from '../../../pages/Products/products/useProductModal';
import Icon from '../../Icon';
import ProductFormFields from '../ProductFormFields/ProductFormFields';
import './ProductModal.scss';

interface ProductModalProps {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  isEditMode?: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  editProductData: ProductFormData | undefined;
}

export const ProductModal = ({
  opened,
  setOpened,
  isEditMode = false,
  setIsEditMode,
  editProductData,
}: ProductModalProps) => {
  const { t } = useTranslation();

  const onCloseModal = () => {
    setIsEditMode(false);
    setOpened(false);
    form.clearErrors();
    form.reset();
  };

  const { form, typesOptions, mutateProduct, isError, isLoading, setFieldValue, values } =
    useProductModal({ isEditMode, editProductData, closeModal: onCloseModal });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const productData = isEditMode
      ? { organizationProductId: editProductData?.id, ...values }
      : { ...values };
    mutateProduct(productData);
  };

  // If required fields are empty, disable the Create button.
  const requiredFields: string[] = ['name', 'version', 'manufacturer'];
  const isRequiredFieldEmpty = (values: { [key: string]: any }) =>
    Object.entries(values).some(([key, value]) => !value && requiredFields.includes(key));

  return (
    <Modal
      opened={opened}
      onClose={onCloseModal}
      title={t(isEditMode ? 'product.edit-modal.title' : 'product.create-modal.title')}
      size="lg"
    >
      <Collapse in={isError} pb={12}>
        <Alert icon={<Icon icon="circle-info" />} color="red">
          {isEditMode
            ? t('tables.products.edit-product-error')
            : t('tables.products.create-product-error')}
        </Alert>
      </Collapse>
      <form onSubmit={handleSubmit} className="product-modal__form">
        <ProductFormFields
          form={form}
          typesOptions={typesOptions}
          setFieldValue={setFieldValue}
          values={values}
        />
        <Group position="right" mt="md">
          <Button
            variant="default"
            type="button"
            onClick={onCloseModal}
            disabled={isLoading}
          >
            {t('product.cancel')}
          </Button>
          <Button type="submit" disabled={isLoading || isRequiredFieldEmpty(form.values)}>
            {t(isEditMode ? 'product.update-cta' : 'product.cta')}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
