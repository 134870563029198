import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Loading from '../../components/Loading';
import ProductOverviewAbout from '../../components/Product/Overview/About';
import ProductOverviewHighlights from '../../components/Product/Overview/Highlights';
import ProductOverviewTopAssets from '../../components/Product/Overview/TopAssets';
import ProductOverviewTopVulns from '../../components/Product/Overview/TopVulns';
import { useFetchProductOverview } from '../Products/products/useFetchProductOverview';
import styles from './ProductOverview.module.scss';

export const ProductOverview = () => {
  const { t } = useTranslation();
  let { productId } = useParams<{ productId: string }>();

  const {
    data: productOverviewResponse,
    isLoading,
    isFetched,
    isSuccess,
    isError,
  } = useFetchProductOverview({
    productId,
  });

  if (isError) {
    return (
      <ul className="page-errors anim-slideInUpShort">
        {productOverviewResponse?.errors?.map((error) => <li>{error}</li>)}
        <li>{t('page.product.fetchProductError')}</li>
      </ul>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isSuccess && productOverviewResponse.data) {
    const {
      countVulnerabilities,
      countHighRiskAssets,
      description,
      top5Assets,
      top5Vulns,
      labels,
    } = productOverviewResponse.data;

    return (
      <div className={styles.product_overview}>
        <Grid>
          <Grid.Col span={12} md={9}>
            <ProductOverviewHighlights
              countHighRiskAssets={countHighRiskAssets}
              inKevVulns={countVulnerabilities?.inKEV || 0}
              mitigateVulns={countVulnerabilities?.mitigate || 0}
            />
            <Grid align="stretch" pt={6}>
              <Grid.Col span={12} md={6}>
                {!!top5Assets.length && (
                  <ProductOverviewTopAssets top5Assets={top5Assets} />
                )}
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                {!!top5Vulns.length && <ProductOverviewTopVulns top5Vulns={top5Vulns} />}
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12} md={3} mb={12}>
            <ProductOverviewAbout description={description} labels={labels} />
          </Grid.Col>
        </Grid>
      </div>
    );
  }
};
