import * as i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import appConfig from './configs/appConfig';

// Workaround for https://github.com/isaachinman/next-i18next/issues/1781
declare module 'i18next' {
  interface TFunction {
    <
      TKeys extends i18next.TFuncKey = string,
      TInterpolationMap extends object = i18next.StringMap,
    >(
      key?: TKeys | string,
      options?: i18next.TOptions<TInterpolationMap> | string,
    ): string;
  }
}

function genRandomNumber(length: number) {
  const chars = '0123456789';
  const charLength = chars.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}

const versionHash = genRandomNumber(10);
const supportedLngs = ['en-US', 'ja', 'it', 'de', 'ko'];

// For examples on adding <Trans>, t(), etc via hooks or HOC, see:
// https://github.com/i18next/react-i18next/blob/master/example/react/src/App.js

i18next
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs,
    fallbackLng: (code) => {
      if (!code || code === 'en' || !supportedLngs.includes(code)) return ['en-US'];
      return [code, 'en-US']; // If code is valid, use it but fallback to en-US so that missing keys are in English
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // Updated detection settings:
    detection: {
      // If the user is not logged in or has no DB preference,
      // we rely on the browser's navigator for a best guess.
      order: ['navigator'],
      caches: [], // We don't store the *user preference* in localStorage/cookie
    },
    backend: {
      // ChainedBackend: first localStorage, then http
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          // localStorage backend
          expirationTime: 2 * 60 * 60 * 1000, // 2 hours
          defaultVersion: 'v' + versionHash,
        },
        {
          loadPath: `/locales/{{lng}}/{{ns}}.json?version=${versionHash}`,
        },
      ],
    },
  });

export default i18next;
