import { RangeSlider, RangeSliderProps } from '@mantine/core';
import styles from './ManifestRangeSlider.module.scss';

export const ManifestRangeSlider = (
  props: Omit<
    RangeSliderProps,
    'classNames' | 'className' | 'styles' | 'size' | 'thumbSize'
  >,
) => (
  <RangeSlider
    {...props}
    size={'xs'}
    thumbSize={20}
    classNames={{
      thumb: styles.thumb,
      bar: styles.bar,
    }}
  />
);
