import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { Flex, Radio, Stack } from '@mantine/core';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSBOMDownload } from '../../../contexts/SBOMDownload.context';
import ManifestModal from '../../ManifestModal/ManifestModal';

interface DownloadSBOMModalProps {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  product: InterfaceOrganizationProduct;
}

export const DownloadSBOMModal = ({
  opened,
  setOpened,
  product,
}: DownloadSBOMModalProps) => {
  const { t } = useTranslation();
  const { addToDownloadQueue, isLoading } = useSBOMDownload();

  const [selectedFormat, setSelectedFormat] = useState<string | undefined>(undefined);

  const onClose = () => {
    setOpened(false);
    setSelectedFormat(undefined);
  };

  const downloadSbomHandler = (format?: string) => {
    if (!product._id || !format) {
      return;
    }
    addToDownloadQueue({ product, format, onSuccess: onClose });
  };

  return (
    <ManifestModal
      size="md"
      opened={opened}
      withCloseButton={true}
      onClose={() => {
        onClose();
      }}
      title={t('product.download-modal.title')}
      subtitle={`${t('product.download-modal.description')}`}
      body={
        <>
          <Flex w="100%" direction="column" gap="12px">
            <Radio.Group
              required
              size="md"
              withAsterisk
              value={selectedFormat}
              onChange={setSelectedFormat}
              label={t('product.download-modal.call-to-action')}
            >
              <Stack mt={'xs'} spacing="xs">
                <Radio
                  key={'CycloneDX'}
                  value={'CycloneDX'}
                  label={t('product.download-modal.cyclonedx')}
                />
                <Radio
                  key={'SPDX'}
                  value={'SPDX'}
                  label={t('product.download-modal.spdx')}
                />
              </Stack>
            </Radio.Group>
          </Flex>
        </>
      }
      primaryAction={{
        label: t('product.download-modal.download'),
        onClick: () => downloadSbomHandler(selectedFormat),
        isLoading: isLoading,
        isDisabled: !selectedFormat,
      }}
      secondaryAction={{
        label: t('product.download-modal.cancel'),
        onClick: onClose,
      }}
    />
  );
};
