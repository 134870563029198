import React, { useState } from 'react';
import {
  Navbar,
  Title,
  Card,
  Checkbox,
  Button,
  Center,
  Text,
  Stack,
} from '@mantine/core';

import styles from './Agreement.module.scss';
import { useTranslation } from 'react-i18next';
import { ManifestLogo } from '../Icons/ManifestLogoIcon/ManifestLogoIcon';

interface AgreementComponentProps {
  agreementText: string;
  orgName?: string;
  onAccept: () => void;
  isAccepting?: boolean;
}

const AgreementComponent = ({
  agreementText,
  orgName,
  onAccept,
  isAccepting = false,
}: AgreementComponentProps) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  const handleContinueClick = () => {
    if (isChecked) {
      onAccept();
    }
  };

  return (
    <div>
      <Navbar height={60} p="md" withBorder={true} className={styles.navbar}>
        <Center>
          <ManifestLogo height={30} />
        </Center>
      </Navbar>
      <Center mt="md">
        <Stack spacing="md" align="center" style={{ maxWidth: 600, width: '100%' }}>
          <Title order={1} align="center" mt={56} className={styles.title}>
            {t('page.agreement.title', {
              orgName: orgName,
            })}
          </Title>
          <Card shadow="sm" padding="lg" mb={10} style={{ width: '100%' }}>
            <Text>{agreementText}</Text>
          </Card>
          <Checkbox
            classNames={{
              label: styles.checkboxLabel,
            }}
            label={t('page.agreement.accept-acknowledgement')}
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <Button
            size="md"
            onClick={handleContinueClick}
            disabled={!isChecked}
            loading={isAccepting}
            mt={8}
          >
            {t('global.continue')}
          </Button>
        </Stack>
      </Center>
    </div>
  );
};

export default AgreementComponent;
