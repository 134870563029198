import { MantineNumberSize, Slider } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';
import styles from './Slider.module.scss';

interface SliderGradientProps {
  description: string;
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  onBlur?: () => void;
  onChange?: (value: number) => void;
  onChangeEnd?: (value: number) => void;
  onFocus?: () => void;
  size?: MantineNumberSize | undefined;
}

export const SliderGradient = forwardRef(
  (
    {
      description,
      min,
      max,
      step,
      value,
      onBlur,
      onChange,
      onFocus,
      onChangeEnd,
      size,
    }: SliderGradientProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    return (
      <div className={styles.container}>
        <div className={styles.slider}>
          <Slider
            data-testid="slider"
            label={description}
            ref={ref}
            classNames={{
              bar: styles.bar,
              thumb: styles.thumb,
            }}
            min={min}
            max={max}
            step={step}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onChangeEnd={onChangeEnd}
            onFocus={onFocus}
            size={size}
          />
        </div>
        <span className={styles.description}>{description}</span>
      </div>
    );
  },
);
