import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import {
  rejectFailedRequestOrEmptyData,
  SucessApiResponseWithData,
} from '../../../lib/request/rejectFailedRequest/rejectFailedRequest';

export interface ProductQueryParams {
  enabled?: boolean;
  productId?: string;
}

export const useFetchProduct = ({
  enabled = true,
  productId,
}: ProductQueryParams): UseQueryResult<
  SucessApiResponseWithData<InterfaceOrganizationProduct>,
  Error
> => {
  let fetchUrl = `product/${productId}`;
  const queryKey = [`product`, `/${fetchUrl}`];

  return useQuery({
    enabled,
    keepPreviousData: true,
    queryKey: queryKey,
    queryFn: async () => {
      const response = await AxiosProxy.get({ url: fetchUrl });

      const validResponse =
        await rejectFailedRequestOrEmptyData<InterfaceOrganizationProduct[]>(response);

      return {
        success: validResponse.success,
        data: validResponse.data[0],
        errors: validResponse.errors,
      };
    },
  });
};
