import { Select, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { usePutLocale } from './usePutLocale';
import { getLanguageName } from './getLanguageName';

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(() => {
    const savedLanguage = i18n.language;
    return savedLanguage === 'default' ? navigator.language : savedLanguage;
  });
  const { mutate } = usePutLocale();

  const handleLanguageChange = async (value: string) => {
    let newLanguage = value;

    if (value === 'default') {
      newLanguage = navigator.language;
    }

    setLanguage(newLanguage);

    try {
      await i18n.changeLanguage(newLanguage);
      mutate({ locale: value === 'default' ? 'default' : value });
    } catch (error) {
      captureExceptionWithMessage('Error changing language:', error);
    }
  };

  useEffect(() => {
    // Listen for i18n 'languageChanged' event in case something else changes it
    const handleLanguageChanged = (language: string) => setLanguage(language);

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [i18n]);

  const getDefaultLabel = () => {
    const browserLanguage = navigator.language;
    const languageName = getLanguageName(browserLanguage, i18n.language);
    return `🌐 ${t('Default')} (${languageName})`;
  };

  return (
    <>
      <Text id="language-label">{t('page.userSettings.profile.language')}</Text>
      <Select
        aria-labelledby="language-label"
        placeholder={t('page.userSettings.profile.pick-one')}
        data={[
          { value: 'default', label: getDefaultLabel() },
          { value: 'en-US', label: '🇺🇸 English' },
          { value: 'it', label: '🇮🇹 Italiano' },
          { value: 'ja', label: '🇯🇵 日本語' },
          { value: 'de', label: '🇩🇪 Deutsch' },
          { value: 'ko', label: '🇰🇷 한국어' },
        ]}
        required
        value={language.startsWith('default') ? 'default' : language}
        onChange={handleLanguageChange}
        withinPortal={false} // Ensure options render within the DOM tree
      />
    </>
  );
};
