import {
  Button,
  Drawer,
  Flex,
  Group,
  Menu,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_RowSelectionState,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { useFetchSharingPortal } from '../../../api/sharingPortal/hooks/useFetchSharingportal/useFetchSharingPortal';
import appConfig from '../../../configs/appConfig';
import { useCheckActiveAssets } from '../../../hooks/assets/useCheckActiveAssets';
import { useCheckProductAssociation } from '../../../hooks/assets/useCheckProductAssociation';
import { PostAssetsParams, usePostAssets } from '../../../hooks/mutations/usePostAssets';
import { usePutAssetLabels } from '../../../hooks/mutations/usePutAssetLabels';
import { useFetchAssets } from '../../../hooks/queries/useFetchAssets';
import { useFetchSBOMsByAssetIDs } from '../../../hooks/queries/useFetchSBOMsByAssetIDs';
import { useAuth } from '../../../hooks/useAuth';
import { useNotifications } from '../../../hooks/utils/useNotifications';
import {
  useLocalStoragePaginationState,
  useURLandLocalStorageSortingState,
  useURLFilterArrayState,
  useURLFilterBooleanState,
  useURLFilterNumberState,
  useURLFilterStringState,
} from '../../../hooks/utils/usePersistentStates';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { ShareSbomModal } from '../../../pages/Assets/ShareSbomModal/ShareSbomModal';
import { AssetsActions } from '../../../pages/Sboms/Menu/AssetsActions';
import { MenuButtonItem } from '../../../pages/Sboms/Menu/MenuButtonItem';
import { SBOMSelectionData } from '../../../pages/Sboms/models/SBOMSelectionData.model';
import { InterfaceFullAsset } from '../../../types/FullInterfaces';
import Icon from '../../Icon/Icon';
import ManageLabelsModal from '../../Labels/ManageLabelsModal';
import { getDefaultTableOptions } from '../../MRT/ManifestMRT';
import OssIngestModal from '../../OssIngestModal';
import { AssetsTableFilters } from '../AssetsTableFilters';
import DataTableFooter from '../DataTableFooter';
import { AssetsTableModals } from './AssetsTableModals';
import { DateCreatedColumn } from './columns/DateCreated.column';
import { LabelsColumn } from './columns/Labels.column';
import { NameColumn } from './columns/Name.column';
import { RiskScoreColumn } from './columns/RiskScore.column';
import { SupplierColumn } from './columns/Supplier.column';
import { VersionColumn } from './columns/Version.column';
import { VulnerabilitiesColumn } from './columns/Vulnerabilities.column';
import { SupplierHeader } from './headers/Supplier.header';
import { resolveName, resolveVulnerabilitiesCount } from './resolvers';
import useAssetsModals, { AssetModals } from './state/useAssetModals';

const defaultTableOptions = getDefaultTableOptions<InterfaceFullAsset>();

interface Props {
  embedded?: boolean;
  hasFilters?: boolean;
  productId?: string;
}

interface SbomToShare {
  sbomId: string;
  isActiveAsset: boolean;
}

const getAssetsToShare = (
  sbomsSelected: SBOMSelectionData[],
  assets: InterfaceFullAsset[],
): SbomToShare[] => {
  return sbomsSelected.map(({ assetId: assetQueryId, id: sbomId }) => {
    const foundAsset = assets.find((asset) => asset._id?.toString() === assetQueryId);

    return {
      sbomId: sbomId,
      isActiveAsset: foundAsset ? Boolean(foundAsset.isActive) : true,
    };
  });
};

export const AssetsTable = ({
  embedded = false,
  hasFilters = true,
  productId,
}: Props) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  const { checkUserAccess } = useAuth();
  const hasWriteAccess = checkUserAccess('write');
  const { error } = useNotifications();
  const navigate = useNavigate();
  const {
    fetch: fetchSBOMs,
    data: selectedSBOMs,
    isLoading: isLoadingSBOMs,
  } = useFetchSBOMsByAssetIDs();

  const {
    mutateAsync: putAssetLabels,
    isLoading: isAddingLabels,
    isError: isErrorAddingLabels,
  } = usePutAssetLabels();
  const { data: sharingPortal } = useFetchSharingPortal();
  const isEnabledSharingPortal = Boolean(sharingPortal?.isActive);
  const { modalState, modalControls } = useAssetsModals();
  const checkProductAssociationsMutation = useCheckProductAssociation();
  const [assetAssociation, setAssetAssociation] = useState<Array<string | undefined>>([]);
  const handleAssetProductAssociationCheck = (
    selectedAssetIds: Array<string | undefined>,
    action: 'active' | 'inactive',
  ) => {
    setAssetAssociation(selectedAssetIds);
    const validAssetIds = selectedAssetIds.filter(
      (assetId): assetId is string => !!assetId,
    );

    if (
      localStorage.getItem('markInactiveAssetWarning') !== 'false' &&
      action === 'inactive' &&
      validAssetIds.length > 0
    ) {
      checkProductAssociationsMutation.mutate(validAssetIds, {
        onSuccess: (data) => {
          if (data.success && data.data) {
            modalControls.openModal(AssetModals.MarkInactiveAssetWarning);
          } else {
            toggleAssetActiveStatus(selectedAssetIds, action);
          }
        },
      });
    } else {
      toggleAssetActiveStatus(selectedAssetIds, action);
    }
  };

  const handleMarkInactiveAssetWarning = (
    selectedAssetIds: Array<string | undefined>,
  ) => {
    modalControls.closeModal(AssetModals.MarkInactiveAssetWarning);
    toggleAssetActiveStatus(selectedAssetIds, 'inactive');
  };

  const [selectedAsset, setSelectedAsset] = useState<InterfaceFullAsset>();
  const [filterSidebarOpen, setFilterSidebarOpen] = useState(false);
  const [editLabelModalOpen, setEditLabelModalOpen] = useState(false);
  const [ossIngestModalOpen, setOssIngestModalOpen] = useState(false);
  const [currentActionAsset, setCurrentActionAsset] = useState<InterfaceFullAsset | null>(
    null,
  );
  const [pagination, setPagination] = useLocalStoragePaginationState('assets', {
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useURLandLocalStorageSortingState('assets', [
    { id: 'riskScore', desc: true },
  ]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedRowsAmount, setSelectedRowsAmount] = useState<number>(0);
  const [selectedProductsAmount, setSelectedProductsAmount] = useState<number>(0);
  const [filterOnlyHasVulns, setFilterOnlyHasVulns] = useURLFilterBooleanState(
    'onlyHasVulns',
    false,
  );
  const [filterOnlyHasCriticalVulns, setFilterOnlyHasCriticalVulns] =
    useURLFilterBooleanState('onlyHasCriticalVulns', false);
  const [filterOnlyHasLicenseIssues, setFilterOnlyHasLicenseIssues] =
    useURLFilterBooleanState('onlyHasLicenseIssues', false);
  const [filterOnlyFirstParty, setFilterOnlyFirstParty] = useURLFilterBooleanState(
    'onlyFirstParty',
    false,
  );
  const [filterIsActive, setFilterIsActive] = useURLFilterBooleanState(
    'onlyActiveAssets',
    true,
  );
  const [filterSharedToPortal, setFilterSharedToPortal] = useURLFilterBooleanState(
    'sharedOnPortal',
    false,
  );
  const [filterOnlyThirdParty, setFilterOnlyThirdParty] = useURLFilterBooleanState(
    'onlyThirdParty',
    false,
  );
  const [filterRiskScore, setFilterRiskScore] = useURLFilterArrayState('riskScore', []);
  const [filterCveIds, setFilterCveIds] = useURLFilterArrayState('cveIds', []);
  const [filterCvssScore, setFilterCvssScore] = useURLFilterNumberState('cvssScore', 0);
  const [filterAssetAuthor, setFilterAssetAuthor] = useURLFilterArrayState(
    'assetAuthor',
    [],
  );
  const [filterAssetSupplier, setFilterAssetSupplier] = useURLFilterArrayState(
    'assetSupplier',
    [],
  );
  const [filterAssetTypes, setFilterAssetTypes] = useURLFilterArrayState('assetType', []);
  const [filterLicenseTypes, setFilterLicenseTypes] = useURLFilterArrayState(
    'licenseIssueType',
    [],
  );
  const [filterLabels, setFilterLabels] = useURLFilterArrayState('labels', []);
  const [filterAssetName, setFilterAssetName] = useURLFilterStringState('assetName', ''); //search
  // export to pdf
  const [viewExportDocument, setViewExportDocument] = useState<boolean>(false);

  const { mutate: mutateAssets } = usePostAssets();
  const { mutate: checkActiveAssets } = useCheckActiveAssets({
    onSuccess: (data) => {
      if (
        data.success &&
        data.data === true &&
        localStorage.getItem('confirmInactiveAssetAssociation') !== 'false'
      ) {
        modalControls.openModal(AssetModals.ConfirmAssociation);
      } else {
        modalControls.openModal(AssetModals.ProductsAssociation);
      }
    },
    onError: (error) => {
      captureExceptionWithMessage('Unable to check active assets:', error);

      modalControls.openModal(AssetModals.ProductsAssociation);
    },
  });
  const { error: errorNotification, info: infoNotification } = useNotifications();
  const queryClient = useQueryClient();

  const toggleAssetActiveStatus = (
    assetIds: Array<string | undefined>,
    state: 'active' | 'inactive',
  ) => {
    const isActive = state === 'active';
    const assetsToUpdate = assetIds
      .filter((assetId): assetId is string => !!assetId)
      .reduce<PostAssetsParams['assets']>((acc, assetId) => {
        acc[assetId] = isActive;
        return acc;
      }, {});

    mutateAssets(
      { assets: assetsToUpdate },
      {
        onSuccess: (response: { success: boolean; errors: string[]; data: [] }) => {
          try {
            if (response.errors.length) {
              errorNotification({
                title: t('global.error'),
                message: t('component.shareDownloadOptionButton.assetsUpdateFailed', {
                  assetsLength: Object.keys(assetsToUpdate).length,
                }),
              });
            } else {
              queryClient.invalidateQueries(['assets']);
              infoNotification({
                title: t('global.info'),
                message: t(
                  state === 'active'
                    ? 'component.shareDownloadOptionButton.assetsMarkedAsActive'
                    : 'component.shareDownloadOptionButton.assetsMarkedAsInactive',
                  {
                    assetsLength: Object.keys(assetsToUpdate).length,
                  },
                ),
              });
            }
          } catch (err) {
            captureExceptionWithMessage('toggleAssetActiveStatus:', err);
          }
        },
      },
    );
  };

  //memos
  const columns = useMemo<MRT_ColumnDef<InterfaceFullAsset>[]>(
    () => [
      {
        accessorFn: (row) => row.riskScore,
        id: 'riskScore',
        header: t('tables.assets.headers.riskScore'),
        size: 80,
        sortDescFirst: true,
        mantineTableHeadCellProps: {
          sx: {
            flexGrow: 0,
          },
        },
        mantineTableBodyCellProps: {
          sx: {
            flexGrow: 0,
          },
        },
        Cell: RiskScoreColumn,
      },
      {
        accessorFn: (row) => resolveName(row),
        id: 'name',
        header: t('tables.assets.headers.assetName'),
        size: 300,
        Cell: NameColumn,
      },
      {
        accessorFn: (row) => row.version,
        header: t('tables.assets.headers.version'),
        size: 150,
        Cell: VersionColumn,
      },
      {
        accessorFn: (row) => row.supplier,
        id: 'supplier',
        size: 150,
        header: t('tables.components.headers.supplier'),
        Header: SupplierHeader,
        Cell: SupplierColumn,
      },
      {
        accessorFn: (row) => resolveVulnerabilitiesCount(row),
        id: 'countVulnerabilities.total',
        header: t('tables.components.headers.vulns'),
        enableSorting: true,
        Cell: VulnerabilitiesColumn,
      },
      {
        accessorFn: (row) => row.dateCreated,
        id: 'dateCreated',
        header: t('tables.assets.headers.uploaded'),
        enableSorting: true,
        Cell: DateCreatedColumn,
      },
      {
        accessorFn: (row) => row.name,
        id: 'labels',
        header: t('tables.assets.headers.labels'),
        Header: '',
        enableSorting: false,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => (
          <LabelsColumn<InterfaceFullAsset>
            row={row}
            onLabelClick={(labelId: string) => setFilterLabels([labelId])} // New usage
            hasWriteAccess={hasWriteAccess}
          />
        ),
      },
      {
        accessorFn: (row) => row.sharedOnPortal,
        id: 'sharedOnPortal',
        header: '',
        Header: '',
        enableSorting: false,
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) =>
          row.original.sharedOnPortal && sharingPortal?.isActive ? (
            <Icon icon={'user-group'} size="lg" iconStyle="fal" />
          ) : null,
      },
      {
        id: 'dateModified',
        header: '',
        //hidden column to sort by
      },
    ],
    [t, sharingPortal?.isActive],
  );
  const selectedAssetIDs = useMemo(() => Object.keys(rowSelection), [rowSelection]);
  const [sbomsToShare, setSbomsToShare] = useState<SbomToShare[]>([]);

  //handlers
  const getCurrentFilters = () => {
    const currentFilters = [];
    if (filterOnlyHasVulns)
      currentFilters.push({ field: 'onlyHasVulns', value: filterOnlyHasVulns });
    if (filterOnlyHasCriticalVulns)
      currentFilters.push({
        field: 'onlyCriticalVulns',
        value: filterOnlyHasCriticalVulns,
      });
    if (filterOnlyHasLicenseIssues)
      currentFilters.push({
        field: 'onlyHasLicenseIssues',
        value: filterOnlyHasLicenseIssues,
      });

    if (filterOnlyFirstParty) {
      currentFilters.push({ field: 'onlyFirstParty', value: filterOnlyFirstParty });
    }
    if (filterOnlyThirdParty) {
      currentFilters.push({ field: 'onlyThirdParty', value: filterOnlyThirdParty });
    }
    if (filterIsActive) {
      currentFilters.push({ field: 'onlyActiveAssets', value: filterIsActive });
    }
    if (filterSharedToPortal) {
      currentFilters.push({ field: 'sharedOnPortal', value: filterSharedToPortal });
    }
    if (filterRiskScore?.length) {
      currentFilters.push({ field: 'riskScore', value: filterRiskScore });
    }
    if (filterCveIds.length) {
      currentFilters.push({ field: 'cveIds', value: filterCveIds });
    }
    if (filterCvssScore) {
      currentFilters.push({ field: 'cvssScore', value: [filterCvssScore, 10] });
    }
    if (filterAssetAuthor.length) {
      currentFilters.push({ field: 'assetAuthor', value: filterAssetAuthor });
    }
    if (filterAssetSupplier.length) {
      currentFilters.push({ field: 'assetSupplier', value: filterAssetSupplier });
    }
    if (filterAssetTypes.length) {
      currentFilters.push({ field: 'assetType', value: filterAssetTypes });
    }
    if (filterLicenseTypes.length) {
      currentFilters.push({ field: 'licenseIssueType', value: filterLicenseTypes });
    }
    if (filterLabels.length) {
      currentFilters.push({ field: 'labels', value: filterLabels });
    }
    if (filterAssetName) {
      currentFilters.push({ field: 'assetName', value: [filterAssetName] });
    }

    return currentFilters;
  };

  const redirectToAssetsPage = () => navigate('/assets');

  const handleDownloadSbom = async (id: string) => {
    try {
      const sbomId = (await AxiosProxy.get({ url: `sboms?assetId=${id}` }))?.data?.[0]
        ?._id;
      window.open(
        `//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${sbomId}?redirect=1`,
      );
    } catch (e) {
      error({
        title: t('notifications.sbom-download-error.title'),
        message: t('notifications.sbom-download-error.message'),
      });
    }
  };

  //queries
  const {
    data: {
      data: fetchedAssets = [],
      queryInfo: { totalCount: countAssets = 0 } = {},
    } = {},
    isLoading: isLoadingAssets,
    isError: isErrorLoadingAssets,
    refetch: refetchAssets,
  } = useFetchAssets({
    productId,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    filters: getCurrentFilters(),
  });

  //prefetch the next page for faster navigation
  useFetchAssets({
    productId,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 2,
    limit: pagination.pageSize,
    filters: getCurrentFilters(),
  });

  const {
    data: {
      data: fetchedModalAssets = [],
      queryInfo: { totalCount: countModalAssets = 0 } = {},
    } = {},
    isFetching: isFetchingModalAssets,
    isLoading: isLoadingModalAssets,
    isError: isErrorLoadingModalAssets,
  } = useFetchAssets({
    page: pagination.pageIndex + 1,
    limit: 100,
  });

  //effects
  useEffect(() => {
    if (selectedAssetIDs.length) fetchSBOMs(selectedAssetIDs);
  }, [selectedAssetIDs]);

  const table = useMantineReactTable<InterfaceFullAsset>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: 'manifest-data-table-no-footer',
    },
    layoutMode: 'grid', //better control of column widths
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 50,
        mantineTableHeadCellProps: {
          align: 'center',
          sx: {
            flexGrow: 0,
          },
        },
        mantineTableBodyCellProps: {
          align: 'center',
          sx: {
            flexGrow: 0,
          },
        },
      },
      'mrt-row-actions': {
        Header: '',
        size: 30,
        mantineTableBodyCellProps: {
          align: 'center',
        },
      },
    },
    columns,
    data: fetchedAssets,
    columnFilterDisplayMode: 'custom',
    getRowId: (originalRow) => originalRow?._id?.toString(),
    enableRowActions: true,
    enableRowSelection: true,
    enableFilters: false,
    enablePagination: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: countAssets,
    initialState: {
      ...defaultTableOptions.initialState,
      columnVisibility: {
        dateModified: false,
      },
    },
    state: {
      globalFilter: filterAssetName,
      pagination,
      sorting,
      rowSelection,
      isLoading: isLoadingAssets,
      showLoadingOverlay: false,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setFilterAssetName,
    mantineSearchTextInputProps: {
      placeholder: t('tables.assets.search-assets'),
      sx: {
        minWidth: '260px',
      },
    },
    renderRowActionMenuItems: ({ row }) => {
      const isActive = row.original?.isActive != false;
      const assetId = row.original._id?.toString();

      return (
        <>
          {!embedded && (
            <>
              <Menu.Item
                onClick={() => {
                  setSelectedAsset(row.original);
                  setViewExportDocument(true);
                }}
                icon={<Icon icon="arrow-down-to-bracket" />}
              >
                {t('tables.assets.actions.downloadReport')}
              </Menu.Item>
              <Menu.Item
                onClick={() => handleDownloadSbom(row.original._id?.toString() || '')}
                icon={<Icon icon="arrow-down-to-bracket" />}
              >
                {t('tables.assets.actions.downloadSbom')}
              </Menu.Item>
              {isEnabledSharingPortal &&
                hasWriteAccess &&
                appConfig.enableEmailFeatures && (
                  <MenuButtonItem
                    onClick={() => {
                      if (row.original.sbomId) {
                        setSbomsToShare([
                          { isActiveAsset: isActive, sbomId: row.original.sbomId },
                        ]);
                      }
                    }}
                    icon={'share-nodes'}
                    disabled={!row.original.sbomId}
                    disabledText={t('tables.assets.invalidSbom')}
                    text={t('tables.assets.actions.shareSbom')}
                  />
                )}
              {hasWriteAccess && (
                <Menu.Item
                  onClick={() => {
                    setCurrentActionAsset(row.original);
                    setEditLabelModalOpen(true);
                  }}
                  icon={<Icon icon="tag" />}
                >
                  {t('tables.assets.actions.editLabels')}
                </Menu.Item>
              )}
              {hasWriteAccess && (
                <Menu.Item
                  onClick={() => {
                    handleAssetProductAssociationCheck(
                      [assetId],
                      isActive ? 'inactive' : 'active',
                    );
                  }}
                  icon={isActive ? <Icon icon={'eye-slash'} /> : <Icon icon={'eye'} />}
                >
                  {isActive
                    ? t('tables.assets.actions.markAsInactive')
                    : t('tables.assets.actions.markAsActive')}
                </Menu.Item>
              )}
            </>
          )}

          {embedded && productId && hasWriteAccess ? (
            <>
              <Menu.Item
                onClick={() => {
                  setSelectedAsset(row.original);
                  modalControls.openModal(AssetModals.RemoveAsset);
                }}
                icon={<Icon icon="ban" />}
              >
                {t('tables.assets.actions.remove-from-product')}
              </Menu.Item>
            </>
          ) : null}
        </>
      );
    },
    renderTopToolbar: ({ table }) => (
      <>
        {hasFilters && (
          <Flex justify="space-between" m="1rem 0">
            <>
              <Flex gap="xs">
                <MRT_GlobalFilterTextInput table={table} />
                <Button
                  onClick={() => setFilterSidebarOpen(!filterSidebarOpen)}
                  leftIcon={<Icon icon="filter" />}
                  variant="default"
                >
                  {t('tables.assets.filter')}
                </Button>
              </Flex>
              <Flex gap="xs">
                {Object.keys(rowSelection).length > 0 && hasWriteAccess && (
                  <Button
                    onClick={() => setEditLabelModalOpen(true)}
                    variant="default"
                    leftIcon={<Icon icon="tag" />}
                  >
                    {t('tables.assets.actions.add-labels')}
                  </Button>
                )}
                {/* <Button
            onClick={() => setOssIngestModalOpen(true)}
            variant="default"
            leftIcon={<Icon icon="github" iconStyle='fab' />}
          >
            {t('tables.assets.actions.import-oss')}
          </Button> */}
                <Menu>
                  <Menu.Target>
                    <Button
                      leftIcon={<Icon icon="arrow-up-arrow-down" />}
                      variant="default"
                    >
                      {t('tables.assets.sort')}
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() => table.setSorting([{ id: 'name', desc: false }])}
                    >
                      {t('tables.assets.sorting.nameAsc')}
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => table.setSorting([{ id: 'name', desc: true }])}
                    >
                      {t('tables.assets.sorting.nameDesc')}
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => table.setSorting([{ id: 'riskScore', desc: false }])}
                    >
                      {t('tables.assets.sorting.riskAsc')}
                    </Menu.Item>

                    <Menu.Item
                      onClick={() => table.setSorting([{ id: 'riskScore', desc: true }])}
                    >
                      {t('tables.assets.sorting.riskDesc')}
                    </Menu.Item>

                    <Menu.Item
                      onClick={() =>
                        table.setSorting([{ id: 'dateCreated', desc: true }])
                      }
                    >
                      {t('tables.assets.sorting.uploadDateAsc')}
                    </Menu.Item>

                    <Menu.Item
                      onClick={() =>
                        table.setSorting([{ id: 'dateCreated', desc: false }])
                      }
                    >
                      {t('tables.assets.sorting.uploadDateDesc')}
                    </Menu.Item>

                    <Menu.Item
                      onClick={() =>
                        table.setSorting([{ id: 'dateModified', desc: true }])
                      }
                    >
                      {t('tables.assets.sorting.updatedDateAsc')}
                    </Menu.Item>

                    <Menu.Item
                      onClick={() =>
                        table.setSorting([{ id: 'dateModified', desc: false }])
                      }
                    >
                      {t('tables.assets.sorting.updatedDateDesc')}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
                {/* <Feature featureName={EXPERIMENTS.PRODUCTS}> */}
                {hasWriteAccess && (
                  <Button
                    onClick={() => {
                      setSelectedRowsAmount(Object.keys(rowSelection).length);
                      checkActiveAssets(selectedAssetIDs);
                    }}
                    disabled={!Object.keys(rowSelection).length}
                  >
                    <Group spacing="xs">
                      <Icon icon="plus" />
                      <span>{t('product.products-association.title')}</span>
                    </Group>
                  </Button>
                )}
                <AssetsActions
                  openShareToPortalModal={() => {
                    setSbomsToShare(getAssetsToShare(selectedSBOMs, fetchedAssets));
                  }}
                  SBOMs={selectedAssetIDs.length ? selectedSBOMs : []}
                  loading={isLoadingSBOMs}
                  selectedAssets={selectedAssetIDs}
                  handleAssetProductAssociationCheck={handleAssetProductAssociationCheck}
                  hasWriteAccess={hasWriteAccess}
                />
              </Flex>
            </>
          </Flex>
        )}
        {embedded && productId && hasWriteAccess ? (
          <Flex justify="flex-end" m="1rem 0">
            {Object.keys(rowSelection).length ? (
              <Button
                onClick={() =>
                  modalControls.openModal(AssetModals.BulkRemoveAssetsFromProduct)
                }
                variant="default"
                disabled={!Object.keys(rowSelection).length}
              >
                <Group spacing="xs">
                  <Icon icon="ban" />
                  <span>{t('page.assets.remove-from-product')}</span>
                </Group>
              </Button>
            ) : (
              <Button onClick={redirectToAssetsPage}>
                <Group spacing="xs">
                  <Icon icon="circle-plus" />
                  <span>{t('page.assets.add-assets')}</span>
                </Group>
              </Button>
            )}
          </Flex>
        ) : (
          <div style={{ marginTop: '20px' }}></div>
        )}
      </>
    ),
  });

  return (
    <>
      {isErrorLoadingAssets && (
        <ul className="page-errors anim-slideInUpShort">
          <li>{t('tables.assets.unable-to-fetch-assets')}</li>
        </ul>
      )}
      {!embedded && (
        <>
          <Title mb="sm" order={1} className="anim-slideInDownShort">
            {t('page.assets.header')}
          </Title>
          <Text className="anim-slideInUpShort">
            {t(filterIsActive ? 'page.assets.totalActive' : 'page.assets.total', {
              count: countAssets?.toLocaleString() as any,
            })}
          </Text>
        </>
      )}
      <div className="list-assets">
        <MantineReactTable table={table} />
        <Drawer
          title={t('global.filters')}
          onClose={() => setFilterSidebarOpen(false)}
          opened={filterSidebarOpen}
          position="right"
          overlayProps={{ opacity: 0.3 }}
          size="xs"
          shadow="xl"
          styles={{
            inner: {
              top: '58px',
            },
            content: {
              border: 'solid 2px var(--color-bg-layout-stroke)',
              overflowX: 'hidden',
            },
          }}
        >
          <AssetsTableFilters
            filterOnlyHasVulns={filterOnlyHasVulns}
            setFilterOnlyHasVulns={setFilterOnlyHasVulns}
            filterOnlyHasCriticalVulns={filterOnlyHasCriticalVulns}
            setFilterOnlyHasCriticalVulns={setFilterOnlyHasCriticalVulns}
            filterOnlyHasLicenseIssues={filterOnlyHasLicenseIssues}
            setFilterOnlyHasLicenseIssues={setFilterOnlyHasLicenseIssues}
            filterOnlyFirstParty={filterOnlyFirstParty}
            filterOnlyThirdParty={filterOnlyThirdParty}
            setFilterOnlyFirstParty={setFilterOnlyFirstParty}
            setFilterOnlyThirdParty={setFilterOnlyThirdParty}
            filterSharedToPortal={filterSharedToPortal}
            filterIsActive={filterIsActive}
            setFilterSharedToPortal={setFilterSharedToPortal}
            setFilterIsActive={setFilterIsActive}
            filterRiskScore={filterRiskScore}
            setFilterRiskScore={setFilterRiskScore}
            filterCveIds={filterCveIds}
            setFilterCveIds={setFilterCveIds}
            filterCvssScore={filterCvssScore}
            setFilterCvssScore={setFilterCvssScore}
            filterAssetAuthor={filterAssetAuthor}
            setFilterAssetAuthor={setFilterAssetAuthor}
            filterAssetSupplier={filterAssetSupplier}
            setFilterAssetSupplier={setFilterAssetSupplier}
            filterAssetTypes={filterAssetTypes}
            setFilterAssetTypes={setFilterAssetTypes}
            filterLicenseTypes={filterLicenseTypes}
            setFilterLicenseTypes={setFilterLicenseTypes}
            filterLabels={filterLabels}
            setFilterLabels={setFilterLabels}
          />
        </Drawer>
        <DataTableFooter
          currentPage={pagination.pageIndex}
          limitPerPage={pagination.pageSize}
          totalResults={countAssets}
          onChangePage={setPagination}
        />
      </div>
      {sbomsToShare.length > 0 && (
        <ShareSbomModal
          resources={sbomsToShare}
          onChangeAcceses={() => {
            refetchAssets();
            setRowSelection({});
          }}
          onClose={() => {
            setSbomsToShare([]);
          }}
        />
      )}
      {editLabelModalOpen && (
        <ManageLabelsModal
          putLabels={putAssetLabels}
          entityType="asset"
          labelIds={
            currentActionAsset
              ? {
                  [currentActionAsset._id?.toString()!]:
                    currentActionAsset.labelIds?.map((l) => l.toString()) || [],
                }
              : table.getSelectedRowModel().rows.reduce(
                  (acc, row) => {
                    acc[row.original._id?.toString()!] =
                      row.original.labelIds?.map((l) => l.toString()) || [];
                    return acc;
                  },
                  {} as Record<string, string[]>,
                )
          }
          open={editLabelModalOpen}
          onClose={() => {
            setCurrentActionAsset(null);
            setEditLabelModalOpen(false);
          }}
        />
      )}
      <OssIngestModal
        open={ossIngestModalOpen}
        onClose={() => {
          setOssIngestModalOpen(false);
        }}
      />
      <AssetsTableModals
        modalState={modalState}
        modalControls={modalControls}
        state={{
          productId,
          selectedAsset,
          fetchedAssets,
          fetchedModalAssets,
          rowSelection,
          selectedRowsAmount,
          selectedProductsAmount,
          viewExportDocument,
          assetAssociation,
        }}
        actions={{
          setRowSelection,
          setSelectedProductsAmount,
          setViewExportDocument,
          handleMarkInactiveAssetWarning,
        }}
      />
    </>
  );
};
