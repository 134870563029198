// ProductFormFields.tsx
import { CloseButton, Flex, MultiSelect, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { ChangeEvent, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchLabels } from '../../../hooks/queries/useFetchLabels';
import { ProductFormData } from '../../../pages/Products/products/usePostProduct';

interface ProductFormFieldsProps {
  form: UseFormReturnType<Omit<ProductFormData, 'id'>>;
  typesOptions: { value: string; label: string }[];
  setFieldValue: (field: string, value: any) => void;
  values: Omit<ProductFormData, 'id'>;
}

const ProductFormFields = ({
  form,
  typesOptions,
  setFieldValue,
  values,
}: ProductFormFieldsProps) => {
  const { t } = useTranslation();
  const { data: fetchedLabels = [] } = useFetchLabels({});

  const handleRemoveLabel = (labelIdToRemove: string) => {
    const filteredLabels = values.labels.filter((labelId) => labelId !== labelIdToRemove);
    setFieldValue('labels', filteredLabels);
  };

  /*const handleTypeSelect = (selectedValues: string[]) => {
    setFieldValue('types', selectedValues);
  };*/

  return (
    <>
      <TextInput
        required
        label={t('product.product-name')}
        placeholder={t('product.product-name-placeholder')}
        value={values.name}
        error={form.errors.name}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setFieldValue('name', event.currentTarget.value)
        }
      />
      <TextInput
        required
        label={t('product.version')}
        placeholder={t('product.version-placeholder')}
        value={values.version}
        error={form.errors.version}
        onChange={(event) => setFieldValue('version', event.currentTarget.value)}
      />
      <TextInput
        required
        label={t('product.manufacturer')}
        placeholder={t('product.manufacturer-placeholder')}
        value={values.manufacturer}
        error={form.errors.manufacturer}
        onChange={(event) => setFieldValue('manufacturer', event.currentTarget.value)}
      />
      {/*<MultiSelect
        label={t('product.type')}
        placeholder={t('product.type-placeholder')}
        value={values.types}
        onChange={handleTypeSelect}
        data={typesOptions}
        searchable
        nothingFound="No options"
      />*/}
      <TextInput
        label={t('product.description')}
        placeholder={t('product.description-placeholder')}
        value={values.description}
        onChange={(event) => setFieldValue('description', event.currentTarget.value)}
      />
      <MultiSelect
        clearable
        searchable
        label={t('tables.assets.filters.labels')}
        placeholder={t('global.select')}
        data={fetchedLabels.map((label) => ({
          color: label.color,
          label: label.name!,
          value: label._id?.toString()!,
        }))}
        itemComponent={forwardRef<HTMLDivElement, any>(
          ({ label, color, ...rest }, ref) => (
            <Flex ref={ref} {...rest}>
              <div className="label-color-container">
                <div className="label-color" style={{ backgroundColor: color }} />
              </div>
              <div className="label-name">{label}</div>
            </Flex>
          ),
        )}
        valueComponent={forwardRef<HTMLDivElement, any>(
          ({ label, color, value, onRemove, ...rest }, ref) => (
            <Flex ref={ref} {...rest}>
              <div className="label-color-container">
                <div className="label-color" style={{ backgroundColor: color }} />
              </div>
              <div className="label-name">{label}</div>
              <CloseButton
                onMouseDown={() => handleRemoveLabel(value)}
                variant="transparent"
                size={22}
                iconSize={14}
                tabIndex={-1}
              />
            </Flex>
          ),
        )}
        value={values.labels}
        onChange={(value) => {
          if (Array.isArray(value) && value.length === 0) {
            setFieldValue('labels', []);
          } else {
            const updatedLabels = Array.isArray(value) ? value : [value];
            const newLabelsSet = new Set([...values.labels, ...updatedLabels]);
            setFieldValue('labels', Array.from(newLabelsSet));
          }
        }}
        rightSection={values.labels?.length ? null : undefined}
      />
    </>
  );
};

export default ProductFormFields;
