import { createContext, ReactNode, useContext, useReducer } from 'react';
import { ProductDispatch, productReducer, ProductState } from './products.reducer';

export const initialProductState: ProductState = {
  isOpenSidebar: false,
};

interface ProductProviderProps {
  children: ReactNode;
}

const ProductsContext = createContext<ProductState>(initialProductState);
const ProductsDispatchContext = createContext<ProductDispatch | undefined>(undefined);

export const ProductsProvider = ({ children }: ProductProviderProps) => {
  const [state, dispatch] = useReducer(productReducer, initialProductState);

  return (
    <ProductsContext.Provider value={state}>
      <ProductsDispatchContext.Provider value={dispatch}>
        {children}
      </ProductsDispatchContext.Provider>
    </ProductsContext.Provider>
  );
};

export const useProductState = (): ProductState => {
  const context = useContext(ProductsContext);
  if (context === undefined) {
    throw new Error('useProductsState must be used within a ProductProvider');
  }
  return context;
};

export const useProductDispatch = (): ProductDispatch => {
  const context = useContext(ProductsDispatchContext);
  if (context === undefined) {
    throw new Error('useProductDispatch must be used within a ProductProvider');
  }
  return context;
};
