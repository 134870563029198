import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { Stack, Text } from '@mantine/core';
import { MRT_Cell, MRT_Row } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import ClickableRegion from '../../../components/ClickableRegion';
import { ProductIcon } from '../../../components/Icons/ProductIcon/ProductIcon';
import { truncateString } from '../../../lib/truncateString';
import './ProductName.scss';

export const ProductName = ({
  cell,
  row,
}: {
  cell: MRT_Cell<InterfaceOrganizationProduct>;
  row: MRT_Row<InterfaceOrganizationProduct>;
}) => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ gap: '2px' }}>
      <ClickableRegion
        className="table-link products-table__name"
        regionLabel={t('tables.products.view-product-details')}
        href={`/product/${row.original._id}`}
      >
        <TextComponent color="muted">
          <ProductIcon width={24} height={20} />
        </TextComponent>
        <Text fw="bold" size="md" sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {truncateString(cell.getValue<string>(), 50)}
        </Text>
      </ClickableRegion>
    </Stack>
  );
};
